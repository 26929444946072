// D65 illuminant의 표준 XYZ 좌표
// const d65x = 0.95047;
// const d65y = 1;
// const d65z = 1.08883;

export type RGB = {
  r: number,
  g: number,
  b: number
};

export type LAB = {
  l: number; // 밝기
  a: number; // 녹색에서 붉은색으로의 색상 축
  b: number; // 파란색에서 노란색으로의 색상 축
}

function componentToHex (c: number) {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

/**
 * RGB 값 조합을 hex 값으로 변환해주는 메서드 
 * @param r 0-255
 * @param g 0-255 
 * @param b 0-255
 * @returns Hex valued of RGB
 */
export function rgbToHex (r:number, g:number, b:number){
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

/**
 * 
 * @param hexColor hex color like #00ff00
 */
export function hexToRgb (hexColor: string): RGB {
  let bigint = parseInt(hexColor.replace('#', ''), 16);  // #으로 시작하는 문자열에서 #를 제외하고 16진수로 변환

    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return { r, g, b };
}

export function yiqColor (r:number, g:number, b:number){
  return 0.299 * r +0.587 * g +0.114 * b;
}

export function textColorFromBg (hexColor: string): string {
  const {r, g, b}: RGB = hexToRgb(hexColor);

  if (yiqColor(r, g, b) > 128) {
    return '#424242';
  }
  return '#dbdbdb';
}

export function distanceLab (c1: LAB, c2: LAB): number {
  const dl = c2.l - c1.l;
  const da = c2.a - c1.a;
  const db = c1.b - c1.b;

  return Math.sqrt(dl*dl + da*da + db*db); 
}
