import Konva from 'konva';
import {
	ComponentPropsWithRef,
	useRef,
	useEffect,
	useState,
	SetStateAction,
	Dispatch,
} from 'react';
import { ColorInfo } from 'pages/ColorPicker';
import chroma from 'chroma-js';
// import { getImageCornersColors, createGradient } from './functions';
import './index.css';
import { useSetRecoilState } from 'recoil';
import { clipImageState } from '../../atoms/imageState';

type HuePickColorPickerProps = ComponentPropsWithRef<'div'> & {
	src: string;
	size?: number;
	onPick: (event: PickEvent) => void;
	invertWheel?: boolean;
	setPickedColors: React.Dispatch<React.SetStateAction<string[]>>;
	// setIsPickVisible: Dispatch<SetStateAction<boolean>>;
	setColorInfo: Dispatch<SetStateAction<ColorInfo>>;
	setTouchPosition: (position: { x: number; y: number }) => void;
};

export type PickEvent = {
	image: HTMLCanvasElement;
	imageDataUrl: string;
};

export default function ({
	onPick,
	src,
	// setIsPickVisible,
	setPickedColors,
	setColorInfo,
	setTouchPosition,
}: HuePickColorPickerProps) {
	if (typeof src === 'undefined') {
		throw new Error('Image source is not defined');
	}
	const circleRadius = 18; // 반지름
	const containerRef = useRef(null);
	const blinkIntervalRef = useRef<NodeJS.Timeout | null>(null);
	// const pillLabelRef = useRef<Konva.Label | null>(null);
	// const [blinkCount, setBlinkCount] = useState(0);
	const setClipImage = useSetRecoilState(clipImageState);
	// const pillButtonPositionY = 50; // Pick btn Y축 위치 값
	// const [pickedColor, setPickedColor] = useState<string>('#000000');

	// function getTextFillColor(pickedColor: any) {
	// 	const brightness = chroma(pickedColor).luminance(); // pickedColor의 밝기 계산
	// 	return brightness > 0.5 ? 'black' : 'white';
	// }

	useEffect(() => {
		Konva.hitOnDragEnabled = true;
		const width = window.innerWidth;
		const height = window.innerHeight;
		// 화면상에 보이는 캔버스 크기 및 이벤트를 관리
		const stage = new Konva.Stage({
			//@ts-ignore
			container: containerRef.current,
			width,
			height,
			draggable: false, // true === 드래그 가능
		});

		const layer = new Konva.Layer(); // shape, group 등이 그려지는 공간
		const lensLayer = new Konva.Layer();
		const imageObj = new Image();
		let circle: Konva.Circle; // 클립이미지 원형 영역
		// let pillLabel: Konva.Label; // 픽버튼 라벨

		// blinkIntervalRef.current = setInterval(() => {
		// 	if (pillLabel) {
		// 		pillLabel.visible(!pillLabel.visible());
		// 		layer.draw(); // 변경사항 적용

		// 		setBlinkCount((prevCount) => prevCount + 1);
		// 	}
		// }, 1000);

		const drawForward = () => {
			// circle 속성 정의
			circle = new Konva.Circle({
				x: layer.width() / 2,
				y: layer.height() / 2,
				radius: circleRadius, // 위에서 정의 : 반지름 18
				stroke: 'white', // stroke color
				strokeWidth: 5, // stroke width
				fill: 'rgba(0,0,0,0)', // fill color
				visible: true, // 눈에 보일지 말지 Boolean
				name: 'circle', // non-unique name
			});

			lensLayer.add(circle);

			// pillLabel = new Konva.Label({
			// 	x: layer.width() / 2 - 33.5,
			// 	y: layer.height() / 2 + 32,
			// 	opacity: 1,
			// 	name: 'pillButton',
			// });

			// pillLabel.add(
			// 	new Konva.Tag({
			// 		fill: pickedColor,
			// 		// pointerDirection: 'down', // 화살표 방향
			// 		// pointerWidth: 10, // 화살표 너비
			// 		// pointerHeight: 10, // 화살표 높이
			// 		// lineJoin: 'round', // 모서리 스타일
			// 		shadowColor: 'black', // 그림자 색상
			// 		shadowBlur: 10, // 그림자 블러
			// 		shadowOffset: { x: 10, y: 10 }, // 그림자 위치
			// 		shadowOpacity: 0.5, // 그림자 투명도
			// 		cornerRadius: 20, // 모서리를 둥글게,
			// 		// stroke: 'black',  // 테두리 색상
			// 		// strokeWidth: 2,  // 테두리 두께
			// 		name: 'pillButtonTag', // 이 이름을 사용하여 이벤트 리스너에서 참조
			// 	}),
			// );

			// pillLabel.add(
			// 	new Konva.Text({
			// 		text: 'Pick',
			// 		fontFamily: 'SUIT',
			// 		fontSize: 14,
			// 		padding: 12, // 텍스트 주변 여백
			// 		fill: getTextFillColor(pickedColor),
			// 		align: 'center', // 가로 방향으로 텍스트를 중앙 정렬
			// 		verticalAlign: 'middle', // 수직 방향으로 텍스트를 중앙 정렬
			// 		width: 67,
			// 		height: 32,
			// 		name: 'pillButtonText',
			// 	}),
			// );

			// lensLayer.add(pillLabel);
			// setPillLabel(pillLabel);
			// lensLayer.draw(); // 필요시 레이어 다시 그림
			stage.add(lensLayer); // lensLayer ===  add circle , add pillButton
		};

		imageObj.src = src;

		imageObj.onload = () => {
			// 측면비율
			const imageAspectRatio = imageObj.width / imageObj.height;
			const stageAspectRatio = stage.width() / stage.height();

			let imgWidth, imgHeight;

			// If the image is wider (landscape)
			if (imageAspectRatio > stageAspectRatio) {
				imgWidth = stage.width();
				imgHeight = imgWidth / imageAspectRatio;
			}
			// If the image is taller (portrait)
			else {
				imgHeight = stage.height();
				imgWidth = imgHeight * imageAspectRatio;
			}

			// name 값을 가져옴
			const img = new Konva.Image({
				x: (stage.width() - imgWidth) / 2,
				y: (stage.height() - imgHeight) / 2,
				image: imageObj,
				width: imgWidth,
				height: imgHeight,
				name: 'image',
			});

			layer.add(img); // 픽할 이미지
			stage.add(layer);

			// const corners = getImageCornersColors(imageObj);
			// const gradients = createGradient(corners);

			// const decode = hash => {
			//   const pixels = decode(hash, 32, 32);

			//   const canvas = document.createElement('canvas');
			//   const ctx = canvas.getContext("2d");
			//   const imageData = ctx!.createImageData(width, height);
			//   imageData.data.set(pixels);
			//   ctx!.putImageData(imageData, 0, 0);

			// };

			// const background = decode(encode(getImageData(imageObj).data, imageObj.width, imageObj.height, 4, 4));

			// 최상위 레벨의 컨테이너 블러처리
			// stage.container().style.background = `url('${img.toDataURL()}')`;
			stage.container().style.background = '#222222';

			drawForward();
		};

		// let dragStopped = false;

		// 유클리드 거리 공식 두 점 사이의 직선 거리 계산
		// function getDistance(p1: any, p2: any) {
		// 	return Math.sqrt(
		// 		Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2),
		// 	);
		// }

		// function getCenter(p1: any, p2: any) {
		// 	return {
		// 		x: (p1.x + p2.x) / 2,
		// 		y: (p1.y + p2.y) / 2,
		// 	};
		// }

		// 픽 이벤트 내용
		function getClippedImage(): PickEvent {
			const image = stage.findOne('.image');
			// console.log('이미지 너 뭐야?', image);

			if (!image) {
				throw new Error('이미지 반환에 실패했습니다.');
			}
			// console.log('#02 : fn onPick(getClippedImage())');
			// console.log('확인!!!! original image size', image?.getSize());
			// console.log('확인!!!! original image position', image?.position());
			// console.log('확인!!!! original image scale', image?.scale());
			// console.log('확인!!!! original layer scale', layer?.scale());

			const tempImage = image.clone();

			// circle의 위치와 크기를 얻습니다.
			const circlePosition = circle.position();
			const circleSize = circle.getSize();
			// console.log('circleSize', circleSize);

			// console.log(tempImage.toDataURL());

			// 크롭할 이미지 영역의 좌표와 크기를 계산합니다.
			// const stagePosition = stage.position();
			const tempImagePosition = tempImage.position();
			const cropX = circlePosition.x - circleSize.width / 2;
			const cropY = circlePosition.y - circleSize.height / 2;
			const cropWidth = circleSize.width;
			const cropHeight = circleSize.height;

			// 임시 레이어에 이미지를 추가합니다.
			const tempLayer = new Konva.Layer({
				width: cropWidth,
				height: cropHeight,
			});
			tempLayer.scaleX(layer.scaleX());
			tempLayer.scaleY(layer.scaleY());
			tempLayer.add(tempImage);

			tempImage.absolutePosition({
				x: -cropX + tempImagePosition.x * layer.scaleX(),
				y: -cropY + tempImagePosition.y * layer.scaleY(),
			});
			// const debugPosition = tempImage.getAbsolutePosition();
			// console.log('tempImage.absolutePosition :: ', debugPosition);

			// console.log(tempLayer.toDataURL())

			// 임시 레이어에서 데이터 URL을 추출합니다.
			const canvas = tempLayer.toCanvas({
				width: cropWidth,
				height: cropHeight,
			});
			const dataURL = tempLayer.toDataURL({
				mimeType: 'image/jpeg', // JPEG 포맷으로 지정
				quality: 0.7, // 품질 설정
				width: cropWidth,
				height: cropHeight,
			});

			tempLayer.destroy();
			// const dataSize = Math.round((dataURL.length * 3) / 4);
			// console.log('리턴데이터유알엘', dataURL);
			// console.log('리턴데이터용량', dataSize, 'byte');
			// console.log('리턴캔바스', canvas);

			// setClipImage({ image: canvas, imageDataUrl: dataURL });

			return {
				image: canvas,
				imageDataUrl: dataURL,
			};
		}

		// 이미지 처리 및 색상 추출 로직을 포함하는 함수
		async function getClippedImageAndSetColor() {
			const { image, imageDataUrl } = getClippedImage(); // 기존의 이미지 처리 로직
			setClipImage({ image: image, imageDataUrl });

			const canvas = image;
			const context = canvas.getContext('2d');

			if (context) {
				const imageData = context.getImageData(
					0,
					0,
					canvas.width,
					canvas.height,
				);
				const colors = []; // 색상을 저장할 배열

				for (let i = 0; i < imageData.data.length; i += 4) {
					// 픽셀이 완전히 투명하지 않은 경우에만 색상 배열에 추가
					if (imageData.data[i + 3] > 0) {
						colors.push(
							chroma(
								imageData.data[i],
								imageData.data[i + 1],
								imageData.data[i + 2],
							),
						);
					}
				}

				// 색상 배열이 비어 있지 않은 경우에만 평균 색상 계산
				if (colors.length > 0) {
					const averageColor = chroma.average(colors, 'rgb');
					// setPickedColor(averageColor.hex());
					setPickedColors([averageColor.hex()]);
				}
				// else {
				// 	// 색상 배열이 비어 있을 경우 기본 색상 설정 (예: 검정색)
				// 	setPickedColor('#000000');
				// }
			}
		}
		// stage.on('wheel', (e) => {
		// 	// console.log(e.evt);
		// 	circle.visible(false);
		// 	pillButton.visible(false);

		// 	const deltaY = e.evt.deltaY;

		// 	// const scale = stage.scaleX() * (dist / lastDist);
		// 	const scale = Math.max(1, layer.scaleX() + -deltaY / 100); // 최소 스케일은 초기 스케일

		// 	layer.scaleX(scale);
		// 	layer.scaleY(scale);

		// 	// TODO 이 코드를 살리고, 옮겨진 좌표에 맞게 렌즈 캡처를 진행할 것.
		// 	// layer.setPosition({
		// 	//   x: layer.width() * (1 - scale) / 2,
		// 	//   y: layer.height() * (1 - scale) / 2,
		// 	// })

		// 	// // const scale = stage.scaleX() * (dist / lastDist);
		// 	// const scale = Math.max(1, stage.scaleX() + (-deltaY/100)); // 최소 스케일은 초기 스케일

		// 	// console.log(scale)

		// 	// stage.scaleX(scale);
		// 	// stage.scaleY(scale);
		// });

		// stage.on('touchmove', (e) => {
		// 	e.evt.preventDefault();
		// 	const touch1 = e.evt.touches[0];
		// 	const touch2 = e.evt.touches[1];

		// 	if (touch1 && !touch2) {
		// 		// setLensePosition(touch1);
		// 	}

		// 	if (touch1 && !touch2 && !stage.isDragging() && dragStopped) {
		// 		stage.startDrag();
		// 		dragStopped = false;
		// 	}

		// 	if (touch1 && touch2) {
		// 		if (stage.isDragging()) {
		// 			dragStopped = true;
		// 			stage.stopDrag();
		// 		}

		// 		const p1 = {
		// 			x: touch1.clientX,
		// 			y: touch1.clientY,
		// 		};
		// 		const p2 = {
		// 			x: touch2.clientX,
		// 			y: touch2.clientY,
		// 		};

		// 		if (!lastCenter) {
		// 			lastCenter = getCenter(p1, p2);
		// 			return;
		// 		}
		// 		const newCenter = getCenter(p1, p2);

		// 		const dist = getDistance(p1, p2);

		// 		if (!lastDist) {
		// 			lastDist = dist;
		// 		}

		// 		const pointTo = {
		// 			x: (newCenter.x - stage.x()) / layer.scaleX(),
		// 			y: (newCenter.y - stage.y()) / layer.scaleX(),
		// 		};

		// 		// const scale = stage.scaleX() * (dist / lastDist);
		// 		const scale = Math.max(1, layer.scaleX() * (dist / lastDist)); // 최소 스케일은 초기 스케일

		// 		layer.scaleX(scale);
		// 		layer.scaleY(scale);

		// 		const dx = newCenter.x - lastCenter.x;
		// 		const dy = newCenter.y - lastCenter.y;

		// 		const newPos = {
		// 			x: newCenter.x - pointTo.x * scale + dx,
		// 			y: newCenter.y - pointTo.y * scale + dy,
		// 		};

		// 		stage.position(newPos);

		// 		lastDist = dist;
		// 		lastCenter = newCenter;
		// 	}
		// });

		const setLensePosition = (touch: Touch) => {
			const stagePosition = stage.position();
			const newX = touch.clientX - stagePosition.x;
			const newY = touch.clientY - stagePosition.y;

			// circle의 새 위치 설정
			circle.position({
				x: newX,
				y: newY,
			});

			// 위치 업데이트 pillLabel의 위치는 pillLabel 전체의 중심을 기준으로 설정
			// pillLabel.position({
			// 	x: newX - pillLabel.getWidth() / 2,
			// 	y: newY + pillButtonPositionY - pillLabel.getHeight() / 2,
			// });

			lensLayer.draw(); // 레이어를 다시 그려서 변경사항을 적용
		};

		let isPillButtonTouched = false;

		stage.on('touchstart', async (e) => {
			const targetNode = e.target;
			sessionStorage.removeItem('res-ColorInfo');
			await getClippedImageAndSetColor();
			// setIsPickVisible(true);
			setColorInfo((currentState) => ({
				...currentState, // 현재 상태의 모든 필드를 복사
				hex: '', // hex 필드만 빈 문자열로 업데이트
			}));
			if (
				targetNode.hasName('pillButtonTag') ||
				targetNode.hasName('pillButtonText')
			) {
				isPillButtonTouched = true;
				onPick(getClippedImage());
				return;
			} else {
				isPillButtonTouched = false;
			}

			circle.visible(true);
			setLensePosition(e.evt.touches[0]);
		});

		let lastCenter: any = null;
		let lastDist = 0;

		stage.on('touchmove', (e) => {
			if (isPillButtonTouched) {
				// 'pillButtonTag' 또는 'pillButtonText'가 터치된 상태에서는 이동을 처리하지 않음
				return;
			}
			e.evt.preventDefault(); // 기본 터치 이동 동작을 방지
			const touch = e.evt.touches[0]; // 현재 터치 위치 가져오기

			if (touch) {
				const stagePosition = stage.position();
				const newX = touch.clientX - stagePosition.x;
				const newY = touch.clientY - stagePosition.y;

				// circle과 pillLabel의 새 위치 설정
				circle.position({
					x: newX,
					y: newY,
				});

				// pillLabel.position({
				// 	x: newX - pillLabel.getWidth() / 2,
				// 	y: newY + pillLabel.getHeight() / 2 + 16,
				// });

				lensLayer.draw(); // 변경사항을 적용하기 위해 레이어를 다시 그립니다.
			}
		});

		const isOverImage = (shape: Konva.Shape): boolean => {
			// 이미지 객체 찾기
			const img = layer.findOne('.image');

			if (!img) {
				return false;
			}

			// 이미지와 shape의 위치 및 크기 정보 가져오기
			const imgPos = img.getAbsolutePosition();
			const imgSize = { width: img.width(), height: img.height() };

			const shapePos = shape.getAbsolutePosition();

			const calcResult =
				shapePos.x < imgPos.x + imgSize.width * layer.scaleX() &&
				shapePos.x > imgPos.x &&
				shapePos.y < imgPos.y + imgSize.height * layer.scaleY() &&
				shapePos.y > imgPos.y;

			// shape의 경계와 img의 경계를 비교하여 겹치는지 확인하기
			return calcResult;
		};

		stage.on('touchend', async (e) => {
			const targetNode = e.target;
			await getClippedImageAndSetColor();
			if (stage) {
				const touchPos = stage.getPointerPosition();
				// touchPos가 null이 아닌지 확인
				if (touchPos) {
					setTouchPosition({ x: touchPos.x, y: touchPos.y });
				} else {
					// touchPos가 null일 경우의 대체 처리
					// 예를 들어, 기본값을 설정하거나 오류 처리 로직을 구현할 수 있습니다.
					console.log('터치 위치를 가져올 수 없습니다.');
				}
			} else {
				// stage가 null일 경우의 대체 처리
				console.log('Stage가 초기화되지 않았습니다.');
			}

			// 'pillButtonTag' 이름을 가진 요소가 터치되었는지 확인합니다.
			// 클릭이 끝난 후에는 모든 터치에 대해 가시성을 조절합니다.
			if (
				!targetNode.hasName('pillButtonTag') &&
				!targetNode.hasName('pillButtonText')
			) {
				// 'pillButtonTag' 또는 'pillButtonText' 이외의 곳이 클릭되었을 때의 처리
				if (isOverImage(circle)) {
					circle.visible(true);
					// pillLabel.visible(true);
				} else {
					circle.visible(false);
					// pillLabel.visible(false);
				}
			}

			lastDist = 0;
			lastCenter = null;
			isPillButtonTouched = false;
			lensLayer.draw(); // 변경사항을 적용하기 위해 레이어를 다시 그립니다.
		});

		document.body.style.overflow = 'hidden';

		return () => {
			stage.destroy();
			document.body.style.overflow = 'unset';
			if (blinkIntervalRef.current) {
				clearInterval(blinkIntervalRef.current);
			}
		};
	}, [src]);

	return (
		<>
			<div
				id="wrapper"
				className="hupick-picker-wrapper"
				ref={containerRef}
			></div>
		</>
	);
}
