import { useEffect, useState } from 'react';
import '../styles/ForgotPwd.css';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import PublicModal from '../components/modal/PublicModal';

const ResetPassword = () => {
	const setHeaderOptions = useSetRecoilState(headerOptionsState);
	const { hash } = useParams();
	const navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [isModal, setIsModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalMessage, setModalMessage] = useState('');
	const [shouldNavigate, setShouldNavigate] = useState(false);

	const handleOffModal = () => {
		setIsModal(false);
		if (shouldNavigate) {
			navigate('/signin');
		}
	};

	useEffect(() => {
		setHeaderOptions({
			showBackward: true,
			showHeader: true,
			template: () => (
				<span style={{ color: '#000000' }}>Reset Password</span>
			),
		});
	}, []);

	const handleResetPw = () => {
		if (password !== confirmPassword) {
			setModalTitle('비밀번호 오류');
			setModalMessage('비밀번호가 일치하지 않습니다. 다시 확인해주세요.');
			setIsModal(true);
		} else {
			const formData = new FormData();
			formData.append(
				'request',
				JSON.stringify({
					hashcode: hash,
					user_pw: password,
				}),
			);
			const postReset = async () => {
				try {
					const res = await axios.post(
						`https://api.huepick.net/member/passreset`,
						formData,
					);
					setModalTitle('비밀번호 변경 완료');
					setModalMessage('변경 완료! 새로운 비밀번호로 로그인해주세요.');
					setShouldNavigate(true); // 이동해야 한다고 상태 설정
					setIsModal(true);
				} catch (err) {
					console.error(err);
					setModalTitle('오류 발생');
					setModalMessage('비밀번호 변경 중 오류가 발생했습니다.');
					setIsModal(true);
				}
			};

			postReset();
		}
	};

	return (
		<div className="forgorPwd-bg flex items-center">
			<div className="forgorPwd-bg__container p-5 bg-white rounded-lg shadow-xl">
				<span className="block text-lg font-semibold">
					비밀번호 재설정
				</span>
				<p className="my-3">
					새 비밀번호를 입력하시고 확인 버튼을 눌러주세요.
				</p>
				<input
					type="password"
					className="mt-2 p-2 border rounded w-full"
					placeholder="새 비밀번호"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<input
					type="password"
					className="mt-2 p-2 border rounded w-full"
					placeholder="새 비밀번호 확인"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
				/>
				<button
					className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
					onClick={handleResetPw}
				>
					확인
				</button>
				<br />
			</div>
			{isModal && (
				<PublicModal
					handleOffModal={handleOffModal}
					title={modalTitle}
					message={modalMessage}
				/>
			)}
		</div>
	);
};

export default ResetPassword;
