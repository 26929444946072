import { useEffect, useState } from 'react';
import '../styles/ForgotPwd.css';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';
import { useNavigate } from 'react-router-dom';
import PublicModal from '../components/modal/PublicModal';
import axios from 'axios';

const ForgotPwd = () => {
	const setHeaderOptions = useSetRecoilState(headerOptionsState);
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [isModal, setIsModal] = useState(false);
	const [emailErrorModal, setEmailErrorModal] = useState(false);

	const handleOffModal = () => {
		setIsModal(false);
		setEmailErrorModal(false);
	};

	const isValidEmail = (email: string): boolean => {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	};

	const handleRedirect = () => {
		navigate('/signin'); 
	};

	const handlePostEmail = async () => {
		if (!email.trim() || !isValidEmail(email)) {
			setEmailErrorModal(true);
			return;
		}

		const formData = new FormData();
		formData.append('request', JSON.stringify({ user_id: email })); 
		axios
			.post('https://api.huepick.net/member/forgot', formData)
			.then((response) => {
				console.log('response:', response.data);
				setIsModal(true);
			})
			.catch((error) => {
				console.error('err', error);
			});
	};

	useEffect(() => {
		setHeaderOptions({
			showBackward: true,
			showHeader: true,
			template: () => (
				<span style={{ color: '#000000' }}>Forgot Password</span>
			),
		});
	}, []);

	return (
		<div className="forgorPwd-bg flex items-center">
			<div className="forgorPwd-bg__container p-5 bg-white rounded-lg shadow-xl">
				<span className="block text-lg font-semibold">
					로그인에 문제가 있나요?
				</span>
				<p className="my-3">
					가입한 이메일을 입력하면 다시 계정에 로그인 할 수 있도록
					비밀번호 재설정 링크를 보내드립니다.
				</p>
				<input
					type="email"
					className="mt-2 p-2 border rounded w-full"
					placeholder="이메일 주소"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<button
					onClick={handlePostEmail}
					className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
				>
					로그인 링크 보내기
				</button>
				<br />

				<div className="flex justify-center items-center">
					<span className="orxbar"></span>
					<span className="flex justify-center m-auto text-sm">
						or
					</span>
					<span className="orxbar"></span>
				</div>

				<span
					className="block mt-3 text-center text-gray-600"
					onClick={() => navigate('/createAccount')}
				>
					register
				</span>
			</div>
			{isModal && (
				<PublicModal
					handleOffModal={handleOffModal}
					title="이메일 전송 완료"
					message="발송량에 따라 이메일이 도착하는 시점에 차이가 있을 수 있습니다. 혹시 메일이 도착하지 않았다면, 스팸 메일함도 함께 확인해 주시기 바랍니다."
					onConfirm={handleRedirect}
				/>
			)}
			{emailErrorModal && ( 
				<PublicModal
					handleOffModal={handleOffModal}
					title="이메일 오류"
					message="올바른 이메일 주소를 입력해주세요."
				/>
			)}
		</div>
	);
};

export default ForgotPwd;
