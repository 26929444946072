// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { headerOptionsState } from '../../atoms/appstate';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// 아이콘
import btnHome from '../icons/B-Home.svg';
import btnBack from '../icons/B-Back.svg';
import btnBackWight from '../icons/W-Home.svg';
import btnMy from '../icons/B-My.svg';
import btnMyWight from '../icons/W-My.svg';
// import PalettePng from '../../assets/palette.png';
import Cookies from 'js-cookie';
import axios from 'axios';
// 리코일
import { useSetRecoilState } from 'recoil';
import { userNameState } from '../../atoms/userSate';

export default function () {
	const location = useLocation();
	const headerOptions = useRecoilValue(headerOptionsState);
	const navigate = useNavigate();
	// 기본값 당신은 뉴비
	const [isNewbie, setIsNewbie] = useState(true);
	// console.log('isNewbie:', isNewbie);
	const setUserName = useSetRecoilState(userNameState);
	// console.log('캐시부스터 3 x 아이콘 적용?');

	useEffect(() => {
		const handleLoginConfirm = () => {
			const token = Cookies.get('authToken'); // 쿠키에서 토큰 가져오기
			const formData = new FormData();
			if (token) {
				formData.append('request', JSON.stringify({ token })); // 'request' 키로 토큰을 JSON 형식으로 포함
				axios
					.post('https://api.huepick.net/member/status', formData)
					.then((response) => {
						const firstName = response.data.profile.first_name;
						setUserName(firstName); // Recoil 상태 업데이트
						// console.log('Login 상태는? :', response.data);
						// console.log(
						// 	'생일 null == 뉴비 :',
						// 	response.data.profile.birth,
						// );
						// 생일값이 초기값인 null 이면 뉴비상태로 정의
						if (response.data.profile.birth === null) {
							setIsNewbie(true);
						}

						if (response.data.profile.birth !== null) {
							setIsNewbie(false);
						}
					})
					.catch((error) => {
						console.error('Login not:토큰있는데 안유효', error);
					});
			} else {
				console.log('토큰없음');
			}
		};
		handleLoginConfirm();
	}, [location]);

	const goBackward = () => {
		if (location.pathname === '/pick') {
			navigate('/upload');
		} else if (location.pathname === '/statistics') {
			navigate('/pick');
		} else {
			// 그 외의 경우, 이전 페이지로 이동
			navigate(-1);
		}
	};

	const backward = useMemo(() => {
		if (headerOptions.showHeader && headerOptions.showBackward) {
			const buttonImage =
				location.pathname === '/pick' ? btnBackWight : btnBack;
			const altText = location.pathname === '/pick' ? 'Home' : 'Back';

			return (
				<img
					src={buttonImage}
					alt={altText}
					className="centered-icon"
					style={{ width: '24px', height: '24px' }}
					onClick={() => {
						goBackward();
					}}
				/>
			);
		}

		return <div style={{ color: 'black' }} className="col-span-1"></div>;
	}, [headerOptions, location.pathname]);

	const headerTitle = useMemo(() => {
		if (headerOptions.template) {
			return <>{headerOptions.template()}</>;
		}

		return <span className="header-title">HuePick</span>;
	}, [headerOptions]);

	if (headerOptions.showHeader) {
		return (
			<header
				className="header grid grid-cols-6 justify-center items-center"
				style={{ padding: '0 5%' }}
			>
				{backward}
				<div className="col-span-4">{headerTitle}</div>
				{/* 뉴비가 아니고 회원이면 적용 */}
				{!isNewbie &&
					location.pathname !== '/signup' &&
					location.pathname !== '/statistics' &&
					location.pathname !== '/service/agreement' &&
					location.pathname !== '/service/marketing' && (
						<img
							src={
								location.pathname === '/pick'
									? btnMyWight
									: btnMy
							}
							alt="My Button"
							className="centered-icon"
							style={{ width: '24px', height: '24px' }}
							onClick={() => {
								navigate('/statistics');
							}}
						/>
					)}

				{location.pathname === '/statistics' && (
					<img
						src={btnHome}
						alt="My Button"
						className="centered-icon"
						style={{ width: '24px', height: '24px' }}
						onClick={() => {
							navigate('/upload');
						}}
					/>
				)}
			</header>
		);
	}
	return null;
}
