import { SvgIconProps } from "./icon";

const SvgIcBrightness = (props: SvgIconProps) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" 
  {...props}>
  <path d="M12.5706 8.34015C12.9047 7.49895 14.0953 7.49895 14.4294 8.34014L16.6097 13.83C16.7113 14.086 16.914 14.2887 17.17 14.3903L22.6599 16.5706C23.501 16.9047 23.501 18.0953 22.6599 18.4294L17.17 20.6097C16.914 20.7113 16.7113 20.914 16.6097 21.17L14.4294 26.6599C14.0953 27.501 12.9047 27.501 12.5706 26.6599L10.3903 21.17C10.2887 20.914 10.086 20.7113 9.83003 20.6097L4.34015 18.4294C3.49895 18.0953 3.49895 16.9047 4.34014 16.5706L9.83003 14.3903C10.086 14.2887 10.2887 14.086 10.3903 13.83L12.5706 8.34015Z" fill={props.color}/>
  <path d="M23.5706 5.34015C23.9047 4.49895 25.0953 4.49895 25.4294 5.34015L26.1884 7.25131C26.2901 7.50725 26.4927 7.70995 26.7487 7.81159L28.6599 8.57061C29.501 8.90469 29.501 10.0953 28.6599 10.4294L26.7487 11.1884C26.4927 11.2901 26.2901 11.4927 26.1884 11.7487L25.4294 13.6599C25.0953 14.501 23.9047 14.501 23.5706 13.6599L22.8116 11.7487C22.7099 11.4927 22.5073 11.2901 22.2513 11.1884L20.3401 10.4294C19.499 10.0953 19.499 8.90469 20.3401 8.57061L22.2513 7.81159C22.5073 7.70995 22.7099 7.50725 22.8116 7.25131L23.5706 5.34015Z" fill={props.color}/>
  </svg>  
);
export default SvgIcBrightness;
