import { ComponentPropsWithRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './OnBoardingModal.css';
import icBird from './images/bird.png';
import txt from './images/txt.png';

type ModalOnBoarding = ComponentPropsWithRef<'div'> & {
	onClose?: () => void;
	handleDismiss?: () => void;
	handleModalBackground?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const OnBoardingModal = ({
	// onClose
	handleDismiss,
	handleModalBackground,
}: ModalOnBoarding) => {
	const navigate = useNavigate();

	return (
		<div
			className="fixed top-0 left-0 z-10 w-full h-full bg-black bg-opacity-50 flex items-center justify-center p-4"
			onClick={handleModalBackground}
		>
			<div className="w-full max-w-xs px-8 py-10 flex flex-col items-center bg-[#fff9eb] shadow-lg rounded-md relative">
				<img src={icBird} alt="ic" />

				<img src={txt} className="mt-2.5" alt="ic" />
				<button
					className="btn-home "
					onClick={() => navigate('/upload')}
				>
					Go to Hupick!
				</button>

				<button className="btn-never" onClick={handleDismiss}>
					<span>Never show again</span>
				</button>
			</div>
		</div>
	);
};

export default OnBoardingModal;
