import { SvgIconProps } from "./icon";

const SvgIcFall = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill={props.fill}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color}
      fill={props.fill ?? '#ffffff00'}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m12.241 19.227-7.26-3.34L3 10.431l3.96.682V6.34l3.3 1.364 1.981-5.455 1.98 5.455 3.3-1.364v4.773l3.961-.682-1.98 5.454-7.26 3.341Zm0 0v4.023"
    />
  </svg>
);
export default SvgIcFall;
