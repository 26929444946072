import { atom } from 'recoil';

export const userToken = atom({
	key: 'userToken',
	default: 'asdf',
});

export const termsState = atom({
	key: 'termsState',
	default: [
		{
			termId: 1,
			isRequired: true,
			title: '휴픽 서비스 이용약관 및 마케팅 동의',
			body: '휴픽 서비스 이용약관 본문',
		},
		// {
		//   termId: 2,
		//   isRequired: false,
		//   title: '광고성 정보 수신 및 마케팅 동의',
		//   body: '광고성 정보 수신 및 마케팅 본문',
		// },
	],
});

export const headerOptionsState = atom({
	key: 'headerOptionsState',
	default: {
		showHeader: true,
		showBackward: false,
		template: null as (() => JSX.Element) | null,
	},
});
