import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../../atoms/appstate';
import useEmblaCarousel from 'embla-carousel-react'; // hook
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './OnBoarding.css';
import sam1 from './A-1.png';
import sam2 from './A-2.png';
import sam3 from './A-3.png';
import OnBoardingModal from '../../components/modal/OnBoardingModal';
import { isDesktop } from 'react-device-detect';
import btnNext from './Next-1.png';
// @ts-ignore
import btnStart from './start.png';

const OnBoarding = () => {
	const { t } = useTranslation();
	const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
	const [isOnBoardingModal, setIsOnBoardingModal] = useState(false);
	const navigate = useNavigate();
	const setHeaderOptions = useSetRecoilState(headerOptionsState);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const slides = [sam1, sam2, sam3];
	// 배경색 배열 이미지 배경값 sam1,2,3
	const backgroundColors = ['#fbebbc', '#f9eed2', '#fff9eb'];

	useEffect(() => {
		if (!emblaApi) return;
		emblaApi.on('select', () =>
			setSelectedIndex(emblaApi.selectedScrollSnap()),
		);
	}, [emblaApi]);

	useEffect(() => {
		if (process.env.NODE_ENV === 'production' && isDesktop === true) {
			alert(t('browserModal:run'));
			window.location.href = 'https://huepick.world/';
			return;
		}
		setHeaderOptions((prev) => ({
			...prev,
			showBackward: false,
			showHeader: false,
			template: null,
		}));
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, []);

	const handleDismiss = () => {
		localStorage.setItem('showOnBoarding', 'false');
		setIsOnBoardingModal(false);
		navigate('/upload');
	};

	const handleModalBackground = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setIsOnBoardingModal(false);
		}
	};

	const closeModal = () => {
		setIsOnBoardingModal(false);
	};

	return (
		<div className="overflow-hidden" ref={emblaRef}>
			<div className="flex">
				{slides.map((src, index) => (
					<div
						className="embla__slide"
						key={index}
						style={{
							backgroundColor:
								backgroundColors[
									index % backgroundColors.length
								],
						}}
					>
						<img src={src} alt={`Slide ${index + 1}`} />
					</div>
				))}
			</div>
			<div className="buttons-container">
				<div
					onClick={() => setIsOnBoardingModal(true)}
					className="skip-button"
					style={{ width: '74px', height: '33.83px' }}
				>
					Skip
				</div>
				<div>
					{slides.map((_, index) => (
						<div
							key={index}
							className={`embla__dot${
								index === selectedIndex
									? ' embla__dot--active'
									: ''
							}`}
							onClick={() => emblaApi && emblaApi.scrollTo(index)}
						/>
					))}
				</div>
				<div
					className="next-button"
					onClick={() => {
						if (!emblaApi) return;

						const currentIndex = emblaApi.selectedScrollSnap();
						const lastIndex = emblaApi.scrollSnapList().length - 1;

						if (currentIndex === lastIndex) {
							setIsOnBoardingModal(true); // 마지막 슬라이드에 도달했으므로 모달을 표시
						} else {
							emblaApi.scrollNext(); // 아니면 다음 슬라이드로 이동
						}
					}}
				>
					<img src={btnNext} alt="icon" width={74} height={45} />
				</div>
			</div>
			{isOnBoardingModal && (
				<OnBoardingModal
					handleDismiss={handleDismiss}
					onClose={closeModal}
					handleModalBackground={handleModalBackground}
				/>
			)}
		</div>
	);
};

export default OnBoarding;
