import { atom } from 'recoil';

/**
 * 업로드 또는 촬영한 이미지의 Base64 값
 */

interface ClipImageState {
	image: HTMLCanvasElement | null;
	imageDataUrl: string;
}

export const imageState = atom<string | null>({
	key: 'imageState',
	default: null,
});

// atom 정의
export const clipImageState = atom<ClipImageState>({
	key: 'clipImageState',
	default: { image: null, imageDataUrl: '' },
});
