import i18next, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

import * as en from './en';
import * as ko from './ko';

const resources: Resource = {
  'en-US': {
    ...en
  },
  'ko': {
    ...ko
  }
};


const defaultLanguage = navigator.language; 

i18next.use(initReactI18next).init({
  resources,
  lng: defaultLanguage, // "ko-KR", // 초기 설정 언어
  fallbackLng: 'en-US',
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: false
  }
})

export default i18next;


