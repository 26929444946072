import { SvgIconProps } from "./icon";

const SvgIcThermo = (props: SvgIconProps) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
  {...props}>
    <path d="M12 17.5278L12.5002 18.0866L12.75 17.863V17.5278H12ZM20 17.5278H19.25V17.863L19.4998 18.0866L20 17.5278ZM12.75 7C12.75 5.20507 14.2051 3.75 16 3.75V2.25C13.3766 2.25 11.25 4.37665 11.25 7H12.75ZM12.75 17.5278V7H11.25V17.5278H12.75ZM10.75 22C10.75 20.4452 11.4249 19.049 12.5002 18.0866L11.4998 16.9689C10.1201 18.2038 9.25 20.0008 9.25 22H10.75ZM16 27.25C13.1005 27.25 10.75 24.8995 10.75 22H9.25C9.25 25.7279 12.2721 28.75 16 28.75V27.25ZM21.25 22C21.25 24.8995 18.8995 27.25 16 27.25V28.75C19.7279 28.75 22.75 25.7279 22.75 22H21.25ZM19.4998 18.0866C20.5751 19.049 21.25 20.4452 21.25 22H22.75C22.75 20.0008 21.8799 18.2038 20.5002 16.9689L19.4998 18.0866ZM19.25 7V17.5278H20.75V7H19.25ZM16 3.75C17.7949 3.75 19.25 5.20507 19.25 7H20.75C20.75 4.37665 18.6234 2.25 16 2.25V3.75Z" fill={props.color}/>
    <rect x="15" y="15" width="2" height="8" rx="1" fill={props.color}/>
    <circle cx="16" cy="22" r="3" fill={props.color}/>
  </svg>
);
export default SvgIcThermo;
