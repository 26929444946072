import { SvgIconProps } from "./icon";

const SvgIcWinter = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M12.367 2.25v19.901M10.453 3.534 12.38 5.46l1.926-1.926M14.297 20.867l-1.926-1.926-1.926 1.926M20.984 7.225 3.75 17.175M18.914 6.2l-.705 2.63 2.631.705M5.836 19.086l.705-2.63-2.631-.706M20.984 17.175 3.75 7.225M20.844 14.865l-2.631.705.705 2.631M3.906 9.538l2.631-.705-.705-2.631"
    />
  </svg>
);
export default SvgIcWinter;
