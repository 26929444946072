import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';

const PrivacyPolicy = () => {
	const setHeaderOptions = useSetRecoilState(headerOptionsState);

	useEffect(() => {
		setHeaderOptions({
			showBackward: true,
			showHeader: true,
			template: () => (
				<span style={{ color: '#333333' }}>휴픽 개인정보처리 방침</span>
			),
		});
	}, []);

	const navigate = useNavigate();
	const goBackwardSignup = () => {
		navigate('/signup');
	};
	return (
		<div className="w-full h-full py-16 p-8 pt-15 bg-white text-gray-800">
			<h1 className="text-xl font-extrabold leading-8 text-left">
				휴픽 개인정보처리방침
			</h1>
			<div
				className="overflow-auto"
				style={{
					maxHeight: 'calc(100% - 60px - 52px - 2rem)',
				}}
			>
				본 개인정보처리방침은 본 방침이 게시된 웹사이트에서 제공되거나
				수집되는 정보의 처리에 대해 설명합니다. 또한, 타사 웹사이트 또는
				플랫폼에 있는 회사의 애플리케이션을 사용함으로써 제공되거나
				수집되는 정보의 처리에 대해 설명합니다. 회사는 이용자의
				개인정보를 중요시하며, 개인정보처리방침을 통해 회사가
				이용자로부터 제공받은 개인정보를 어떠한 용도와 방식으로 이용하고
				있으며, 개인정보 보호를 위해 어떠한 조치를 취하고 있는지
				알려드립니다. 본 방침은 2024년 월 일부터 시행되며, 이를 개정하는
				경우 웹사이트 공지사항(또는 서면, 팩스, 이메일 등의 개별공지)을
				통해 공지하겠습니다. 수집하는 정보 및 수집 방법 수집하는
				개인정보의 항목 회사가 수집하는 개인정보의 항목은 다음과
				같습니다. 이용자가 제공하는 정보 회사는 이용자가 직접 제공하는
				정보를 수집할 수 있습니다. 인터넷 회원제 서비스의 경우, 이름,
				이메일, 주소, ID, 전화번호, 우편주소, 국가정보, 암호화된 동일인
				식별정보(CI), 중복가입확인정보(DI) 등을 수집합니다. 미성년자인
				경우 법정대리인의 정보(법정대리인의 이름, 생년월일, CI, DI 등)도
				수집합니다. 온라인 결제 서비스의 경우, 이름, 주소, 전화번호,
				이메일 주소 등과 계좌번호, 카드번호 등의 결제정보, 배송 주소지,
				수령인 이름, 수령인 연락처 등의 배송정보, 입찰 구매 판매 등의
				정보를 수집합니다. 소셜 네트워크 서비스의 경우, 이름, 이메일
				주소, ID, 전화번호, 우편주소, 국가정보, 주소록(지인) 등의 정보를
				수집하며, 사진 촬영 장소, 파일 생성 날짜 등의 정보와 회원이
				보거나 이용하는 콘텐츠의 유형, 회원의 활동 빈도나 기간 등 회원의
				서비스 이용에 관한 정보를 수집합니다. 이용자가 서비스를 사용할
				때 수집하는 정보 회사는 이용자가 직접 제공하는 정보 이외에도
				이용자가 회사의 서비스를 사용하는 과정에서 다음과 같은 정보를
				수집할 수 있습니다. 기기정보: 기기 식별자, 운영 체제, 하드웨어
				버전, 기기 설정, 전화번호 등 로그정보: 로그데이터, 이용시간,
				이용자가 입력한 검색어, 인터넷 프로토콜 주소, 쿠키 및 웹비콘 등
				위치정보: GPS, 블루투스 또는 와이파이 신호를 통한 구체적인
				지리적 위치를 포함한 기기 위치에 대한 정보 등(법으로 허용되는
				지역에 한함) 입력정보: 사진촬영 및 기 촬영한 이미지, 퍼스널컬러
				정보, 컬러 선호도, 다양한 설문에 대한 이용자 입력 정보 등
				기타정보: 이용자의 서비스 사용에 있어 선호도, 광고 환경,
				방문하는 페이지 등 수집 방법 회사는 이용자의 정보를 다음과 같은
				방법으로 수집합니다: 웹페이지, 서면양식, 팩스, 전화, 이메일,
				생성정보 수집 툴 등. 협력회사로부터의 제공을 통해서도 정보를
				수집합니다. 수집한 정보의 이용 회사는 수집한 이용자의 정보를
				다음과 같은 목적으로 이용합니다: 회원관리, 본인확인 등 허가받지
				않은 서비스 이용과 부정 이용 등의 탐지 및 방지 이용자가 요구하는
				서비스 제공에 관한 계약 이행 및 요금 정산 등 기존 서비스 개선 및
				신규 서비스 개발 회사 사이트 또는 애플리케이션의 기능 또는 정책
				변경사항의 알림 기타 이용자의 사전 동의에 의한 이용 (예: 마케팅
				광고에 활용 등) 연락처에 등록된 지인 검색•알림•자동 등록, 지인일
				가능성이 있는 다른 이용자의 검색과 알림 회원의 서비스 이용에
				대한 통계, 통계학적 특성에 따른 서비스 제공 및 광고 게재 홍보성
				이벤트 정보 제공 및 참여 기회 제공 준거법 또는 법적 의무의 준수
				본 개인정보처리방침에 명시된 목적과 다른 용도로 정보를 이용할
				경우, 회사는 이용자의 동의를 구하도록 하겠습니다. 수집한 정보의
				공유 회사는 다음의 경우를 제외하고 이용자의 개인정보를 제3자에게
				공유하지 않습니다: 회사의 계열사, 파트너, 서비스 제공업체에 대한
				공유: 회사를 대신하여 결제 처리, 주문 이행, 제품 배송, 분쟁
				해결(결제 및 배송 분쟁 포함) 등 서비스를 회사의 계열사, 파트너,
				서비스 제공업체가 수행하는 경우 이용자가 사전에 동의하는 경우:
				이용자의 개인정보를 특정 업체와 공유하여 해당 업체의 제품 및
				서비스에 대한 정보를 제공받기로 이용자가 선택하는 경우, 이용자의
				개인정보를 소셜 네트워킹 사이트와 같은 타사 사이트 또는 플랫폼과
				공유하도록 이용자가 선택하는 경우, 기타 이용자가 사전에 동의한
				경우 법률상 필요한 경우: 법령상 공개하도록 요구되는 경우, 수사
				목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는
				경우 쿠키, 비콘 및 기타 유사 기술 회사는 '쿠키(cookies)' 또는
				'웹 비콘(web beacons)' 등을 통하여 비개인적인 집합정보를 수집할
				수 있습니다. 쿠키는 회사의 웹사이트를 운영하는 데 이용되는
				서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로,
				이용자의 컴퓨터 하드디스크에 저장됩니다. 웹 비콘은 웹사이트 또는
				이메일 상에 있는 소량의 코드입니다. 웹 비콘을 사용하여 이용자가
				특정 웹이나 이메일 콘텐츠와 상호 작용했는지 여부를 알 수
				있습니다. 이러한 기능은 서비스를 평가하고 개선하여 이용자 경험을
				맞춤 설정하는 데 유용하게 이용되어 이용자에게 더 향상된 서비스를
				제공합니다. 회사가 수집하는 쿠키의 항목 및 수집 목적은 다음과
				같습니다: 반드시 필요한 쿠키: 이 쿠키는 이용자가 회사의 웹사이트
				기능을 이용하는 데 필수적인 쿠키입니다. 이 쿠키를 허용하지
				않으면 장바구니와 전자청구서와 같은 서비스가 제공될 수 없습니다.
				이 쿠키는 마케팅에 사용하거나 사용자가 인터넷에서 방문한
				사이트를 기억하는 데 사용될 수 있는 정보를 수집하지 않습니다. 웹
				브라우저 세션 동안 다른 페이지를 검색할 때 주문 양식에 입력한
				정보를 기억하고, 상품 및 체크아웃 페이지인 경우 주문한 서비스를
				기억하며, 웹사이트에 로그인 여부를 확인합니다. 또한, 회사가
				웹사이트의 작동 방식을 변경할 때 이용자가 회사 웹사이트의 올바른
				서비스에 연결되었는지 확인하고, 서비스의 특정 어플리케이션 또는
				특정 서버로 사용자를 연결합니다. 수행 쿠키: 이 쿠키는 이용자가
				가장 자주 방문하는 페이지 정보와 같이 이용자가 회사 웹사이트를
				어떻게 이용하고 있는지에 대한 정보를 수집합니다. 이 데이터는
				회사 웹사이트를 최적화시키고 이용자가 좀 더 간편하게 웹사이트를
				검색할 수 있도록 도와줍니다. 이 쿠키는 이용자가 누구인지에 대한
				정보를 수집하지 않습니다. 이 쿠키가 수집하는 모든 정보는
				종합적으로 처리되므로 익명성이 보장됩니다. 웹 분석을 통해 웹
				사이트를 사용하는 방법에 대한 통계를 제공하고, 광고 반응 요금을
				통해 회사의 광고가 주는 효과를 확인하며, 제휴사 추적을 통해 회사
				방문자 중 하나가 제휴사의 웹사이트를 방문한 것에 대해 제휴사에게
				익명으로 피드백을 제공합니다. 또한, 오류 관리를 통해 발생하는
				오류를 측정하여 웹사이트를 개선하는 데 도움을 주며, 디자인
				테스트를 통해 회사의 웹사이트의 다른 디자인을 테스트합니다. 기능
				쿠키: 이 쿠키는 서비스를 제공하거나 방문을 개선하기 위해 설정을
				기억하는 데 사용됩니다. 이 쿠키로 수집된 정보는 이용자를
				개별적으로 파악하지 않습니다. 레이아웃, 텍스트 크기, 기본 설정,
				색상 등과 같이 적용한 설정을 기억하고, 회사의 설문 조사에 고객이
				응하는 경우 이를 기억합니다. 대상 쿠키: 이 쿠키는 '좋아요' 버튼
				및 '공유' 버튼과 같은 제3자가 제공하는 서비스와 연결됩니다.
				제3자는 이용자가 회사의 웹사이트를 방문한 것을 인지하여 이러한
				서비스를 제공합니다. 소셜 네트워크로 연결하여 해당 소셜
				네트워크가 이용자의 방문 정보를 사용하여 나중에 다른
				웹사이트에서 이용자를 대상으로 홍보하며, 이용자가 관심이 있을 수
				있는 광고를 제시할 수 있도록 이용자 방문 정보를 광고 대행사에
				제공합니다. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.
				따라서 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나
				쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을
				거부할 수도 있습니다. 단, 이용자가 쿠키 설치를 거부하였을 경우
				회사가 제공하는 일부 서비스에 어려움이 있을 수 있습니다.
				이용자의 접근권한과 선택권 이용자 또는 법정대리인은 정보의
				주체로서 회사의 개인정보 수집, 사용, 공유와 관련하여 다음과 같은
				선택권을 행사할 수 있습니다: 개인정보에 대한 접근권한 개인정보의
				정정 또는 삭제 개인정보 처리의 일시 정지 기존에 제공한 동의의
				철회의 요청 이를 위해 웹페이지의 '회원정보 수정' 메뉴를
				이용하거나, 대표전화 또는 회사 담당부서(또는 개인정보
				관리책임자)에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
				조치하겠습니다. 다만, 회사는 법률에 명시된 타당한 사유 또는 그에
				상응하는 사유가 있는 경우에만 그러한 요청을 거부할 수 있습니다.
				보안 회사는 이용자의 개인정보에 대한 보안을 매우 중요하게
				생각합니다. 회사는 이용자 개인정보의 무단 접근, 공개, 사용 및
				수정을 막기 위해 다음과 같은 보안 조치를 구축하고 있습니다:
				개인정보의 암호화 이용자의 개인정보를 암호화된 통신구간을
				이용하여 전송 비밀번호 등 중요정보는 암호화하여 보관 해킹 등에
				대비한 대책: 해킹이나 컴퓨터 바이러스 등에 의해 이용자의
				개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이
				통제된 구역에 시스템을 설치 어린이 개인정보 보호 회사는
				원칙적으로 13세 미만 또는 관할 법률상 이에 상응하는 최소 연령의
				어린이로부터 정보를 수집하지 않습니다. 회사의 웹사이트, 제품과
				서비스 등은 원칙적으로 일반인을 대상으로 한 웹사이트입니다.
				회사의 웹사이트 또는 애플리케이션에는 연령 제한 기능이 있어서
				어린이가 이용할 수 없도록 하고 있으며, 그러한 기능을 통해
				어린이로부터 고의로 개인정보를 수집하지 않습니다. 어린이
				개인정보를 수집하는 경우, 회사는 부득이 서비스 이용을 위하여
				13세 미만 또는 관할 법률상 이에 상응하는 최소 연령의 어린이의
				개인정보를 수집할 때, 다음과 같은 절차를 추가적으로 거치게
				됩니다: 어린이 개인정보 수집 또는 회사의 제품, 서비스 정보를
				어린이에게 직접 발송하기 위한 보호자 동의 획득 수집한 개인정보
				항목, 목적, 공유 여부를 포함한 회사의 어린이 개인정보보호 방침에
				대해 보호자에게 통보 법정대리인에게 해당 아동의 개인정보에 대한
				접근권, 개인정보의 정정 또는 삭제, 개인정보 처리의 일시 정지,
				기존에 제공한 동의의 철회를 요청할 수 있는 권한 부여 온라인
				활동의 참여에 필요한 것 이상의 개인정보 수집 제한
				개인정보처리방침의 변경 회사는 본 방침을 수시로 수정 또는 변경할
				권리를 보유합니다. 회사가 본 방침을 변경하는 경우, 웹사이트
				공지사항(또는 서면, 팩스, 이메일 등의 개별공지)을 통해 공지하며,
				관계법에서 요구하는 경우에는 이용자의 동의를 구하게 됩니다. 기타
				데이터 전송 회사는 전 세계를 무대로 영업하므로 본
				개인정보처리방침에 명시된 목적을 위해 타국에 위치한 회사나
				타사에 이용자의 개인정보를 제공할 수 있습니다. 개인정보가 전송,
				보유 또는 처리되는 곳에 대해서는 회사가 개인정보 보호를 위한
				합당한 조치를 취합니다. 제3자 사이트 및 서비스 회사의 웹사이트,
				제품, 서비스 등은 제3자의 웹사이트, 제품, 서비스 등의 링크를
				포함할 수 있습니다. 링크된 제3자 사이트의 개인정보처리방침이
				회사의 정책과 다를 수 있습니다. 따라서 이용자들은 링크된 제3자
				사이트의 개인정보처리방침을 추가적으로 검토하셔야 합니다.
				캘리포니아 거주자에 대한 안내 캘리포니아에 거주하시는 분이라면
				특정 권리사항이 추가될 수 있습니다. 회사는 캘리포니아 온라인
				프라이버시 보호법을 준수하기 위해 회원의 개인정보를 보호하기
				위해 필요한 예방책을 마련합니다. 이용자는 개인정보가 누출되었을
				경우, 정보유출 확인을 요청할 수 있습니다. 또한, 회사 웹사이트의
				모든 이용자는 개인 계정에 접속하여 정보 수정 메뉴를 이용하여
				언제든지 정보를 변경할 수 있습니다. 또한, 회사는 웹사이트
				방문자를 추적하지 않으며, '추적 방지' 신호도 사용하지 않습니다.
				회사는 이용자의 동의 없이 광고 서비스를 통해 개인 식별 정보를
				수집하고 타사에 제공하지 않습니다. 한국인 거주자에 대한 안내
				회사는 대한민국 정보통신망법 및 개인정보보호법이 요구하는 몇
				가지 추가 공개 사항을 다음과 같이 안내합니다. 회사 담당부서
				회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을
				처리하기 위하여 아래와 같이 관련 부서를 지정하고 있습니다. 본
				방침에 대해 의문 사항이 있거나, 회사가 보유한 이용자의 정보를
				업데이트하고자 하는 경우, 아래 연락처로 회사에 연락하시기
				바랍니다. 회사 담당부서: 휴픽사업부서 주소: 서울시 강남구
				강남대로 160길 26 302호 전화: +82 02 1800-6355 이메일:
				ask@ocollor.com 최종갱신일: 2024년 월 출처: 글로버 서비스를 위한
				영문 Privacy Policy 작성 가이드라인 – 네이버주식회사
			</div>
			<button
				className="fixed bottom-8 left-1/2 transform -translate-x-1/2 w-[320px] h-[52px] px-10 py-4 rounded-tr-full bg-[#4430BD] text-white font-bold text-[15px] leading-6 text-center"
				style={{
					padding: '16px 40px',
					borderRadius: '100px',
				}}
				onClick={goBackwardSignup}
			>
				확인
			</button>
		</div>
	);
};

export default PrivacyPolicy;
