import { SvgIconProps } from "./icon";

const SvgIcSummer = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill={props.color}
    stroke={props.color}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M12.107 2a.75.75 0 0 0-.75.75v2.495a.75.75 0 0 0 1.5 0V2.75a.75.75 0 0 0-.75-.75Zm.002 6.178a3.616 3.616 0 1 1 0 7.232 3.616 3.616 0 0 1 0-7.232Zm5.116 3.616a5.116 5.116 0 1 0-10.232 0 5.116 5.116 0 0 0 10.232 0Zm-5.868 6.861a.75.75 0 0 1 1.5 0v2.495a.75.75 0 0 1-1.5 0v-2.495ZM4.819 4.402a.75.75 0 0 0 0 1.06l1.764 1.765a.75.75 0 1 0 1.061-1.06L5.88 4.401a.75.75 0 0 0-1.06 0ZM5.88 19.08a.75.75 0 0 1-1.06-1.06l1.764-1.765a.75.75 0 1 1 1.061 1.06L5.88 19.08Zm-3.876-7.284c0 .414.335.75.75.75h2.495a.75.75 0 0 0 0-1.5H2.753a.75.75 0 0 0-.75.75Zm17.385-6.332a.75.75 0 1 0-1.061-1.06l-1.764 1.763a.75.75 0 1 0 1.06 1.061l1.765-1.764Zm0 13.616a.75.75 0 0 1-1.061 0l-1.764-1.764a.75.75 0 0 1 1.06-1.061l1.765 1.764a.75.75 0 0 1 0 1.06Zm2.073-6.534a.75.75 0 1 0 0-1.5h-2.495a.75.75 0 0 0 0 1.5h2.495Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcSummer;
