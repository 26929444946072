import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';

const MarketingConsentForm = () => {
	const setHeaderOptions = useSetRecoilState(headerOptionsState);

	useEffect(() => {
		setHeaderOptions({
			showBackward: true,
			showHeader: true,
			template: () => (
				<span style={{ color: '#333333' }}>
					광고성 정보 수신 및 마켓팅 동의
				</span>
			),
		});
	}, []);

	const navigate = useNavigate();
	const goBackwardSignup = () => {
		navigate('/signup');
	};

	return (
		<div className="w-full h-full py-16 p-8 pt-15 bg-white text-gray-800">
			<h1 className="text-xl font-extrabold leading-8 text-left">
				휴픽 마케팅 정보 수신 동의
			</h1>
			<div
				className="overflow-auto"
				style={{
					maxHeight: 'calc(100% - 60px - 52px - 2rem)',
				}}
			>
				주식회사 오콜로르 (이하 '오콜로르')는 개인정보보호법 및
				정보통신망이용촉진 및 정보보호 등에 관한 법률 등 관계법령에 따라
				광고성 정보를 전송하기 위해 이용자의 사전 수신 동의를 받고
				있습니다. 본 내용은 휴픽 회원 서비스가 적용되는 개별서비스의
				마케팅 수신동의를 포함하고 있으며, 마케팅 정보 수신 동의 시
				개별서비스의 마케팅 수신에 일괄 동의한 것으로 처리됩니다. 1.
				전송방법 마케팅 정보 전송 방법은 오콜로르 서비스 및 휴픽 각
				개별서비스에서 정한 방식에 따라 전송됩니다. 2. 전송내용 발송되는
				마케팅 정보는 수신자에게 오콜로르 및 휴픽 개별서비스에서
				제공하는 혜택(포인트, 쿠폰 등) 정보, 각종 이벤트, 신규 상품 관련
				소식 등 광고성 정보로 관련 법의 규정을 준수하여 발송됩니다. 단,
				광고성 정보 이외에 의무적으로 안내 되어야 하는 정보성 내용은
				수신동의 여부와 무관하게 제공됩니다. 3. 수집항목 - 성별, 연령,
				국가, 지역, 이메일, 이름, 전화번호, 4. 이용목적 - 소식지 서비스
				제공, 신제품 및 이벤트 안내, 이벤트 경품/사은품 제공, 할인행사,
				고객 맞춤 마케팅/판촉 등 관련 이메일 및 SMS 등 발송 5. 철회안내
				- 수신동의 이후에라도 언제든지 동의를 철회할 수 있으며, 수신을
				동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용하실 수
				있습니다. 다만 수신 거부할 경우 신규 서비스나 상품 관련 소식
				등의 마케팅 정보를 제공받지 못할 수 있습니다. 6. 수신동의 변경
				및 보유기간 - 보유 기간 : 마케팅 정보 수신 동의로부터 2년, 기간
				초과 시 동의 절차 재진행 (미동의 시, 즉시 파기) 휴픽 정보수정
				페이지에서 개별서비스의 마케팅 수신동의를 변경(동의/철회)할 수
				있으며, 동의일로부터 회원 탈퇴 혹은 마케팅 수신 동의 해제 시까지
				광고성 정보 전달을 위하여 보유ㆍ이용 됩니다.
			</div>
			<button
				className="fixed bottom-8 left-1/2 transform -translate-x-1/2 w-[320px] h-[52px] px-10 py-4 rounded-tr-full bg-[#4430BD] text-white font-bold text-[15px] leading-6 text-center"
				style={{
					padding: '16px 40px',
					borderRadius: '100px',
				}}
				onClick={goBackwardSignup}
			>
				확인
			</button>
		</div>
	);
};

export default MarketingConsentForm;
