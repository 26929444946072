import { useState, useEffect } from 'react';
import Lottie, { EventListener, Options as LottieOptions } from 'react-lottie';
import animationData from '../assets/lottie/splash.json';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';

function Splash() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [isFontReady, setIsFontReady] = useState<Boolean>(false);
	const [lottieLoopCount, setLottieLoopCount] = useState<number>(0);
	const setHeaderOptions = useSetRecoilState(headerOptionsState);

	const options: LottieOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
	};

	useEffect(() => {
		window.scrollTo(0, 0);

		setHeaderOptions((prev) => ({
			...prev,
			showBackward: false,
			showHeader: false,
			template: null,
		}));

		if (document.fonts) {
			document.fonts
				.load('1em SUIT', 'SUIT font are ready')
				.then(() => {
					console.log('SUIT fonts are ready!');
				})
				.catch((reason) => {
					console.warn(reason);
				})
				.finally(() => {
					// SUIT 폰트가 정상적으로 로드되지 않아도 다음 화면으로 진행해야하므로 finally에 setState를 한다.
					setIsFontReady(true);
				});
		} else {
			setIsFontReady(true);
		}
		document.body.style.overflow = 'hidden';
		return () => {
			setHeaderOptions((prev) => ({
				...prev,
				showHeader: false,
			}));
			document.body.style.overflow = 'unset';
		};
	}, []);

	useEffect(() => {
		// NOTE: 다음 페이지로 넘어갈 조건에 대한 Boolean 값을 여기에 추가합니다.
		const goNext = [lottieLoopCount > 0, isFontReady];

		if (goNext.every(Boolean)) {
			const showOnBoarding = localStorage.getItem('showOnBoarding');
			if (showOnBoarding === 'false' && isDesktop === false) {
				navigate('/upload');
			} else {
				if (
					process.env.NODE_ENV === 'production' &&
					isDesktop === true
				) {
					alert(t('browserModal:run'));
					window.location.href = 'https://huepick.world/';
					return;
				}
				navigate('/onboarding');
			}
		}
	}, [lottieLoopCount, isFontReady]);

	const eventListeners: Array<EventListener> = [
		{
			eventName: 'loopComplete',
			callback: () => {
				setLottieLoopCount((current) => current + 1);
			},
		},
	];

	return (
		<div className="text-center" style={{ margin: 'auto' }}>
			<div>
				<Lottie
					options={options}
					isClickToPauseDisabled
					eventListeners={eventListeners}
				></Lottie>
			</div>
			<h1 className="splash-title">HuePick</h1>
			<h3 className="splash-slogan">{t('main:slogan')}</h3>
		</div>
	);
}

export default Splash;
