import { ComponentPropsWithRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEmblaCarousel from 'embla-carousel-react'; // hook
import './BrowserModal.css';
// 아이콘들
// 다국어 이미지
// import safariDcsKo1 from './images/safari-install-ko1.png';
// import safariDcsKo2 from './images/safari-install-ko2.png';
import safariDcsEn1 from './images/safari-install-en1.png';
import safariDcsEn2 from './images/safari-install-en2.png';
import kakaoDcsKo1 from './images/kakao-install-ko1.png';
import kakaoDcsKo2 from './images/kakao-install-ko2.png';
import kakaoDcsEn1 from './images/kakao-install-en1.png';
import kakaoDcsEn2 from './images/kakao-install-en2.png';
import naverDcsKo1 from './images/naver-install-ko1.png';
import naverDcsKo2 from './images/naver-install-ko2.png';
import chromePwa1 from './images/chrome_pwa1.jpg';
import chromePwa2 from './images/chrome_pwa2.jpg';
import edgeDcsEn1 from './images/edge-install-en1.png';
import edgeDcsEn2 from './images/edge-install-en2.png';
import samsungDcsEn1 from './images/samsung-install-en1.png';
import samsungDcsEn2 from './images/samsung-install-en2.png';
import firefoxDcsEn1 from './images/firefox-install-en1.png';
import firefoxDcsEn2 from './images/firefox-install-en2.png';
//UI 개선 이미지
import safari1 from './images/safari1.png';
import safari2 from './images/safari2.png';
import safari3 from './images/safari3.png';
import {
	isChrome,
	isFirefox,
	isEdge,
	isMobileSafari,
	// isSamsungBrowser,
} from 'react-device-detect';
//

type ModalBrowser = ComponentPropsWithRef<'div'> & {
	onClose?: () => void;
	// handleModalBackground?: (e: React.MouseEvent<HTMLDivElement>) => void;
	handleDismiss?: () => void;
};

const BrowserModal = ({
	onClose, // handleModalBackground
	handleDismiss,
}: ModalBrowser) => {
	const { t, i18n } = useTranslation();
	const userLanguage = navigator.language;
	const languageState: Boolean = userLanguage.startsWith('ko');
	const [isKakao, setIsKakao] = useState<Boolean>(false);
	const [isNaver, setIsNaver] = useState<Boolean>(false);
	const [isSafari, setIsSafari] = useState<Boolean>(false);
	const [isEdgeState, setIsEdgeState] = useState<Boolean>(false);
	const [isFirefoxState, setIsFirefoxState] = useState<Boolean>(false);
	const [isChromeState, setIsChromeState] = useState<Boolean>(false);
	const [isSamsungBrowserState, setIsSamsungBrowserState] =
		useState<Boolean>(false);

	let sUsrAg = navigator.userAgent;

	// 다른방법 찾기
	useEffect(() => {
		// 문자열이 존재하는 경우 true로 내부 블록 실행
		if (sUsrAg.indexOf('KAKAOTALK') > -1) {
			setIsKakao(true);
		} else if (sUsrAg.toLowerCase().indexOf('naver') > -1) {
			setIsNaver(true);
		} else if (isMobileSafari) {
			setIsSafari(true);
		} else if (isEdge) {
			setIsEdgeState(true);
		} else if (isFirefox) {
			setIsFirefoxState(true);
		} else if (isChrome) {
			setIsChromeState(true);
		} else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
			setIsSamsungBrowserState(true);
		}
	}, []);

	const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
	const [selectedIndex, setSelectedIndex] = useState(0);
	const slidesSafari = [safari1, safari2, safari3];
	const slidesSafariDes = [
		'하단 메뉴의 공유하기 버튼을 눌러주세요',
		'홈 화면 추가를 눌러주세요',
		'추가 버튼을 누르면 완료 바탕화면에 앱처럼 휴픽 바로가기가 생겨요',
	];
	const slidesChrome = [chromePwa1, chromePwa2];
	const slidesChromeDes = [
		'설치 버튼을 눌러주세요',
		'⋮ 클릭 후 홈 화면 추가를 눌러주세요',
	];
	const slidesFirefox = [firefoxDcsEn1, firefoxDcsEn2];
	const slidesFirefoxDes = [
		'⋮ 설치 버튼을 눌러주세요',
		'⋮ 클릭 후 설치 버튼을 눌러주세요',
	];
	const slidesKakao = [kakaoDcsKo1, kakaoDcsKo2];
	const slidesKakaoDes = [
		'⍐ 버튼을 눌러주세요',
		'다른 브라우저를 이용해주세요',
	];

	useEffect(() => {
		if (!emblaApi) return;
		emblaApi.on('select', () =>
			setSelectedIndex(emblaApi.selectedScrollSnap()),
		);
	}, [emblaApi]);

	function createDescription(description: string, index: number) {
		// 세 번째 요소에 대한 특별 처리
		if (index === 2) {
			const parts = description.split('완료');
			return (
				<>
					{parts[0]}완료
					<br />
					{parts[1]}
				</>
			);
		}
		// 다른 요소들은 그대로 반환
		return description;
	}

	return (
		<div
			className="fixed top-0 left-0 z-10 w-full h-full bg-black bg-opacity-50 flex items-center justify-center p-4"
			//  onClick={handleModalBackground}
		>
			<div className="w-full max-w-xs px-6 py-4 flex flex-col items-center bg-[#fff9eb] shadow-lg rounded-[20px] relative">
				{/* 네이버 & 카카오가 아닌 전체 헤더 안내문 */}
				{isNaver || isKakao ? (
					<span className=" mb-4  text-center text-[18px] font-bold leading-[28px]">
						Please use a different browser such as Chrome or Safari{' '}
					</span>
				) : (
					<span className=" mb-4  text-center text-[18px] font-bold leading-[28px]">
						{i18n.language === 'en' ? (
							<>
								Add to home screen
								<br />
								and use it like the Hupick app
							</>
						) : (
							<>
								홈 화면에 추가해서
								<br />
								휴픽 앱 처럼 사용해보세요
							</>
						)}
					</span>
				)}

				{/* 카카오톡 브라우저 한국어 경우 */}
				{isKakao && languageState && (
					<>
						<div className="flex w-full justify-between mb-2">
							<div className="overflow-hidden" ref={emblaRef}>
								<div className="flex">
									{slidesKakao.map((src, index) => (
										<div className="__slide" key={index}>
											<span className="flex text-center justify-center text-[12px] text-[#666666] leading-[20px]">
												{slidesKakaoDes[index]}
											</span>
											<img
												src={src}
												alt={`Slide ${index + 1}`}
												className="max-w-[280px] max-h-[355px] mx-auto"
											/>
										</div>
									))}
								</div>
								<div className="__buttons-container">
									<div>
										{slidesKakao.map((_, index) => (
											<div
												key={index}
												className={`__dot${
													index === selectedIndex
														? ' __dot--active'
														: ''
												}`}
												onClick={() =>
													emblaApi &&
													emblaApi.scrollTo(index)
												}
											/>
										))}
									</div>
								</div>
							</div>
						</div>
					</>
				)}

				{/* 카카오톡 브라우저 외국어 경우 */}
				{isKakao && !languageState && (
					<>
						<div className="flex w-full justify-between mb-2">
							<div>
								<span className="" style={{ fontSize: '14px' }}>
									1{')'} ⍐ Click
								</span>
								<img
									src={kakaoDcsEn1}
									alt="safariDcsImg"
									className="w-[120px]"
								/>
							</div>
							<div>
								<span className="" style={{ fontSize: '14px' }}>
									2{')'} Open in Chrome
								</span>
								<img
									src={kakaoDcsEn2}
									alt="safariDcsImg"
									className="w-[120px]"
								/>
							</div>
						</div>
					</>
				)}

				{isSafari && (
					<>
						<div className="flex w-full justify-between mb-2">
							<div className="overflow-hidden" ref={emblaRef}>
								<div className="flex">
									{slidesSafari.map((src, index) => (
										<div className="__slide" key={index}>
											<span className="flex text-center justify-center text-[12px] text-[#666666] leading-[20px]">
												{createDescription(
													slidesSafariDes[index],
													index,
												)}
											</span>
											<img
												src={src}
												alt={`Slide ${index + 1}`}
											/>
										</div>
									))}
								</div>
								<div className="__buttons-container">
									<div>
										{slidesSafari.map((_, index) => (
											<div
												key={index}
												className={`__dot${
													index === selectedIndex
														? ' __dot--active'
														: ''
												}`}
												onClick={() =>
													emblaApi &&
													emblaApi.scrollTo(index)
												}
											/>
										))}
									</div>
								</div>
							</div>
						</div>
					</>
				)}

				{isEdgeState && (
					<>
						<div className="flex w-full justify-between mb-2">
							<div>
								<span className="">1{')'} Click menu</span>
								<img
									src={edgeDcsEn1}
									alt="safariDcsImg"
									className="w-[120px]"
								/>
							</div>
							<div>
								<span className="">2{')'} Add to phone</span>
								<img
									src={edgeDcsEn2}
									alt="safariDcsImg"
									className="w-[120px]"
								/>
							</div>
						</div>
					</>
				)}

				{isFirefoxState && (
					<>
						<div className="flex w-full justify-between mb-2">
							<div className="overflow-hidden" ref={emblaRef}>
								<div className="flex">
									{slidesFirefox.map((src, index) => (
										<div className="__slide" key={index}>
											<span className="flex text-center justify-center text-[12px] text-[#666666] leading-[20px]">
												{slidesFirefoxDes[index]}
											</span>
											<img
												src={src}
												alt={`Slide ${index + 1}`}
												className="max-w-[280px] max-h-[355px] mx-auto"
											/>
										</div>
									))}
								</div>
								<div className="__buttons-container">
									<div>
										{slidesFirefox.map((_, index) => (
											<div
												key={index}
												className={`__dot${
													index === selectedIndex
														? ' __dot--active'
														: ''
												}`}
												onClick={() =>
													emblaApi &&
													emblaApi.scrollTo(index)
												}
											/>
										))}
									</div>
								</div>
							</div>
						</div>
					</>
				)}

				{isSamsungBrowserState && (
					<>
						<div className="flex w-full justify-between mb-2">
							<div>
								<span className="">1{')'} ⍗ Click</span>
								<img
									src={samsungDcsEn1}
									alt="safariDcsImg"
									className="w-[120px]"
								/>
							</div>
							<div>
								<span className="">2{')'} Click "Install"</span>
								<img
									src={samsungDcsEn2}
									alt="safariDcsImg"
									className="w-[120px]"
								/>
							</div>
						</div>
					</>
				)}

				{/* 네이버 인웹이고 한국어 */}
				{isNaver && languageState && (
					<>
						<div className="flex w-full justify-between mb-2">
							<div>
								<span className="">1{')'} Click menu</span>
								<img
									src={naverDcsKo1}
									alt="safariDcsImg"
									className="w-[120px]"
								/>
							</div>
							<div>
								<span className="">2{')'} Open </span>
								<img
									src={naverDcsKo2}
									alt="safariDcsImg"
									className="w-[120px]"
								/>
							</div>
						</div>
					</>
				)}

				{/* 네이버 인웹이고 외국어 */}
				{isNaver && !languageState && (
					<>
						<div className="flex w-full justify-between mb-2">
							<div>
								<span className="">1{')'}</span>
								<img
									src={naverDcsKo1}
									alt="safariDcsImg"
									className="w-[120px]"
								/>
							</div>
							<div>
								<span className="">2{')'}</span>
								<img
									src={naverDcsKo2}
									alt="safariDcsImg"
									className="w-[120px]"
								/>
							</div>
						</div>
					</>
				)}

				{/* 크롬 브라우저 PWA */}
				{isChromeState && (
					<>
						<div className="flex w-full justify-between mb-2">
							<div className="overflow-hidden" ref={emblaRef}>
								<div className="flex">
									{slidesChrome.map((src, index) => (
										<div className="__slide" key={index}>
											<span className="flex text-center justify-center text-[12px] text-[#666666] leading-[20px]">
												{slidesChromeDes[index]}
											</span>
											<img
												src={src}
												alt={`Slide ${index + 1}`}
												className="max-w-[280px] max-h-[355px] mx-auto"
											/>
										</div>
									))}
								</div>
								<div className="__buttons-container">
									<div>
										{slidesChrome.map((_, index) => (
											<div
												key={index}
												className={`__dot${
													index === selectedIndex
														? ' __dot--active'
														: ''
												}`}
												onClick={() =>
													emblaApi &&
													emblaApi.scrollTo(index)
												}
											/>
										))}
									</div>
								</div>
							</div>
						</div>
					</>
				)}

				{isNaver || isKakao ? (
					<button
						className="w-full max-w-xs px-2 py-3 text-white bg-[#4430BD] rounded-[100px] leading-none text-sm"
						onClick={() =>
							(window.location.href = 'https://huepick.world/')
						}
					>
						{t('browserModal:confirm')}
					</button>
				) : (
					<button
						className="w-full max-w-xs px-2 py-3 text-white bg-[#4430BD] rounded-[100px] leading-none text-sm"
						onClick={onClose}
					>
						{t('browserModal:confirm')}
					</button>
				)}

				<button
					className="bg-transparent  mt-2"
					onClick={handleDismiss}
				>
					<span className="border-b border-solid border-white underline">
						{t('browserModal:btn-not-repeat')}
					</span>
				</button>
			</div>
		</div>
	);
};

export default BrowserModal;
