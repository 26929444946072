import { useEffect, useState } from 'react';
import '../styles/CreateAccount.css';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
// aler 공용 모달
import PublicModal from '../components/modal/PublicModal';

const CreateAccount: React.FC = () => {
	const setHeaderOptions = useSetRecoilState(headerOptionsState);
	const navigate = useNavigate();
	const [isModal, setIsModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalMessage, setModalMessage] = useState('');
	const [onModalConfirm, setOnModalConfirm] = useState<() => void>(() => {});

	const handleOffModal = () => {
		setIsModal(false);
	};

	useEffect(() => {
		setHeaderOptions({
			showBackward: true,
			showHeader: true,
			template: () => <span style={{ color: '#000000' }}>Register</span>,
		});
	}, [setHeaderOptions]);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [privacyAccepted, setPrivacyAccepted] = useState(false);
	const [marketingAccepted, setMarketingAccepted] = useState(false);
	const [emailCode, setEmailCode] = useState('');
	const [inputEmailCode, setInputEmailCode] = useState('');
	const [isEmailSent, setIsEmailSent] = useState(false);

	const nameRegex = /^[a-zA-Z가-힣]+$/;
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	const validateForm = () => {
		if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
			setModalTitle('수정필요');
			setModalMessage(
				'이름과 성은 특수 문자, 숫자, 공백을 포함할 수 없습니다.',
			);
			setIsModal(true);
			return false;
		}
		if (!emailRegex.test(email)) {
			setModalTitle('수정필요');
			setModalMessage('유효하지 않은 이메일 형식입니다.');
			setIsModal(true);
			return false;
		}
		if (password === '' || confirmPassword === '') {
			setModalTitle('수정필요');
			setModalMessage('비밀번호를 입력해 주세요.');
			setIsModal(true);
			return false;
		}
		if (password !== confirmPassword) {
			setModalTitle('수정필요');
			setModalMessage('비밀번호가 일치하지 않습니다.');
			setIsModal(true);
			return false;
		}
		if (!termsAccepted || !privacyAccepted) {
			setModalTitle('수정필요');
			setModalMessage('필수 약관에 동의해야 합니다.');
			setIsModal(true);
			return false;
		}
		if (inputEmailCode === '') {
			setModalTitle('수정필요');
			setModalMessage('이메일 인증 코드를 입력해 주세요.');
			setIsModal(true);
			return false;
		}
		if (emailCode !== inputEmailCode) {
			setModalTitle('수정필요');
			setModalMessage('이메일 인증 코드가 일치하지 않습니다.');
			setIsModal(true);
			return false;
		}
		return true;
	};

	const handleRegister = () => {
		if (validateForm()) {
			const formData = new FormData();
			const requestPayload = {
				first_name: firstName,
				last_name: lastName,
				user_id: email,
				user_pw: password,
				agree_01: termsAccepted ? 1 : 0,
				agree_02: privacyAccepted ? 1 : 0,
				agree_03: marketingAccepted ? 1 : 0,
			};
			formData.append('request', JSON.stringify(requestPayload));

			axios
				.post('https://api.huepick.net/member/register', formData)
				.then((response) => {
					console.log(response.data);
					const token: string = response.data.user.token;

					Cookies.set('authToken', token, {
						expires: 3650, // 10년 동안 유효
						secure: true,
						sameSite: 'Strict',
					});

					setModalTitle('회원가입 성공');
					setModalMessage('회원가입 성공! 부가정보를 입력해주세요.');
					setIsModal(true);
					setOnModalConfirm(() => () => navigate('/signup'));
				})
				.catch((error) => {
					console.error('Registration error', error);
					setModalTitle('Error');
					setModalMessage('회원가입 중 오류가 발생했습니다.');
					setIsModal(true);
					setOnModalConfirm(() => () => {});
				});
		}
	};

	const handleEmailAuthentication = () => {
		if (!emailRegex.test(email) || email === '') {
			setModalTitle('수정필요');
			setModalMessage('유효하지 않은 이메일 형식입니다.');
			setIsModal(true);
			return;
		}

		const formData = new FormData();
		formData.append('request', JSON.stringify({ user_id: email }));
		axios
			.post('https://api.huepick.net/member/register/email', formData)
			.then((response) => {
				const code = response.data.auth.code.toString(); // 인증 코드를 문자열로 변환
				setEmailCode(code);
				setIsEmailSent(true);
			})
			.catch((error) => {
				console.error('이메일 에러', error);
				setModalTitle('Error');
				setModalMessage('이메일 인증 중 오류가 발생했습니다.');
				setIsModal(true);
				setOnModalConfirm(() => () => {});
			});
	};

	return (
		<div className="create-account-bg">
			<div className="flex flex-col mb-5">
				<span className="input_title-text">First name</span>
				<input
					type="text"
					className="input-form"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
				/>
			</div>
			<div className="flex flex-col mb-5">
				<span className="input_title-text">Last name</span>
				<input
					type="text"
					className="input-form"
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
				/>
			</div>
			<div className="flex flex-col mb-5">
				<span className="input_title-text">Email</span>
				<div className="flex justify-between">
					<input
						type="text"
						className="input-form-width"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<button
						className="btn-email-post"
						onClick={handleEmailAuthentication}
						disabled={isEmailSent}
					>
						전송
					</button>
				</div>
				{isEmailSent && (
					<span className="text-[12px] text-[#4430bd] mt-2">
						인증번호가 전송 되었습니다.
					</span>
				)}
			</div>
			<div className="flex flex-col mb-5">
				<span className="input_title-text">
					Email verification code
				</span>
				<div className="flex justify-between">
					<input
						type="text"
						className="input-form-width"
						value={inputEmailCode}
						onChange={(e) => setInputEmailCode(e.target.value)}
					/>
				</div>
			</div>
			<div className="flex flex-col mb-5">
				<span className="input_title-text">Password</span>
				<input
					type="password"
					className="input-form"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
			</div>
			<div className="flex flex-col mb-5">
				<span className="input_title-text">Re-Password</span>
				<input
					type="password"
					className="input-form"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
				/>
			</div>
			<div className="block mb-1">
				<div className="flex">
					<label className="flex items-center">
						<input
							style={{
								width: '20px',
								height: '20px',
								cursor: 'pointer',
								marginRight: '10px',
							}}
							type="checkbox"
							checked={termsAccepted}
							onChange={(e) => setTermsAccepted(e.target.checked)}
						/>
					</label>
					<span onClick={() => navigate('/service/agreement')}>
						(필수) HuePick - 이용약관 보기
					</span>
				</div>
			</div>
			<div className="block mb-1">
				<div className="flex">
					<label className="flex items-center">
						<input
							style={{
								width: '20px',
								height: '20px',
								cursor: 'pointer',
								marginRight: '10px',
							}}
							type="checkbox"
							checked={privacyAccepted}
							onChange={(e) =>
								setPrivacyAccepted(e.target.checked)
							}
						/>
					</label>
					<span onClick={() => navigate('/service/privacyPolicy')}>
						(필수) HuePick - 개인정보처리방침 보기
					</span>
				</div>
			</div>
			<div className="block mb-4">
				<div className="flex">
					<label className="flex items-center">
						<input
							style={{
								width: '20px',
								height: '20px',
								cursor: 'pointer',
								marginRight: '10px',
							}}
							type="checkbox"
							checked={marketingAccepted}
							onChange={(e) =>
								setMarketingAccepted(e.target.checked)
							}
						/>
					</label>
					<span onClick={() => navigate('/service/marketing')}>
						(선택) HuePick - 마케팅 정보 수신 동의 보기
					</span>
				</div>
			</div>

			<button className="btn-register" onClick={handleRegister}>
				Register
			</button>
			{isModal && (
				<PublicModal
					handleOffModal={handleOffModal}
					title={modalTitle}
					message={modalMessage}
					onConfirm={onModalConfirm}
				/>
			)}
		</div>
	);
};

export default CreateAccount;
