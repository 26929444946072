import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { headerOptionsState } from '../atoms/appstate';

const TermsOfServiceAgreement = () => {
	const setHeaderOptions = useSetRecoilState(headerOptionsState);

	useEffect(() => {
		setHeaderOptions({
			showBackward: true,
			showHeader: true,
			template: () => (
				<span style={{ color: '#333333' }}>휴픽 이용 약관</span>
			),
		});
	}, []);

	const navigate = useNavigate();
	const goBackwardSignup = () => {
		navigate('/signup');
	};
	return (
		<div className="w-full h-full py-16 p-8 pt-15 bg-white text-gray-800">
			<h1 className="text-xl font-extrabold leading-8 text-left">
				휴픽 이용약관 내용
			</h1>
			<div
				className="overflow-auto"
				style={{
					maxHeight: 'calc(100% - 60px - 52px - 2rem)',
				}}
			>
				이용약관 2024년 07월 25일 시행 제1조 (목적) 1. 이 약관은
				주식회사 오콜로르(이하 “회사”)가 제공하는 휴픽 서비스 이용과
				관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한
				사항을 규정함을 목적으로 합니다. 2. 회원은 본 서비스의 이용과
				관련하여 대한민국법의 적용을 받는 것에 동의합니다. 3. 회원은
				회사에 제공하는 정보 일체에 대하여 회사의 정보 수집 및 이용을
				규율하는 회사의 개인정보 처리방침이 적용됩니다. 회원은 서비스를
				이용함으로써 해당 정보의 대한민국 및/또는 기타 국가 내 보관,
				처리 및 이용을 위한 전송을 포함하여, (개인정보 처리방침에 기술된
				바에 따른) 회사의 정보 수집 및 이용에 동의합니다. 서비스 제공의
				일환으로 회사는 서비스 공지 및 관리 메시지와 같은 특정
				커뮤니케이션을 발송할 수 있으며, 이러한 커뮤니케이션은 서비스 및
				회원의 서비스 계정의 일부로 간주되며, 그 수신을 거부할 수
				없습니다. 제2조 (정의) 본 약관에서 사용하는 용어의 정의는 다음과
				같습니다. 정의되지 않은 용어에 대한 해석은 관계 법령 및 서비스
				별 안내에 정하는 바에 의합니다. 관계법령과 서비스 별 안내에서
				정하지 않은 것은 일반적인 상관례에 따릅니다. 1. “서비스”라 함은
				설치되어 있는 단말기(휴대형 단말기, PC, TV 등의 각종 유무선
				장치를 포함)와 상관없이 “회원”이이 이용할 수 있는 휴픽 및 휴픽
				관련 제반 서비스를 의미합니다. 2. 이용자란 회원가입을 하지
				않고(비회원) 회사가 제공하는 서비스를 이용하는 자를 말합니다. 3.
				"회원"이란 본 약관에 동의하여 회사가 제공하는 서비스를 이용하는
				자를 말합니다. 4. “서비스 이용자”는 이용자 및 회원을 말합니다.
				5. “컨텐츠”란 회원 또는 오콜로르에서 제공되는 각종의 디지털
				정보를 말합니다. 제 3조 (약관 등의 명시와 설명 및 개정) 1.
				회사는 이 약관의 내용을 서비스이용자가 쉽게 알 수 있도록 회사에
				게시하거나 기타의 방법으로 회원에게 공지함으로써 효력이
				발생합니다. 2. 회사는 약관의 규제에 관한 법률,
				정보통신망이용촉진 및 정보보호등에 관한 법률, 전자상거래
				등에서의 소비자보호에 관한 법률, 소비자기본법 등 관련법을
				위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 3. 회사가
				약관을 개정 할 경우에는 적용일자 및 개정사유를 명시하여 현행
				약관과 함께 제 1항의 방식에 따라 개정약관의 적용일자 30일 전부터
				적용일자 전까지 공지합니다. 다만, 회원의 권리 또는 의무에 관한
				중요한 규정의 변경일 경우에는 최소한 30일전에 공지하고
				개정약관을 회원이 등록한 이메일로 발송하여 통지합니다. 4. 회사가
				전항에 따라 개정약관을 공지 또는 통지하면서 약관 변경 적용일까지
				거부의사를 표시하지 않으면 의사표시가 표명된다는 내용을
				알렸음에도 불구하고 회원이 명시적으로 거부의사를 표시하지
				아니하는 경우 회원이 개정약관에 동의한 것으로 간주합니다. 5.
				회원이 변경된 약관에 동의하지 않는 경우 회사는 개정 약관의
				내용을 적용할 수 없으며 이 경우 회원은 서비스의 이용을 중단하고
				이용계약을 해지 할 수 있습니다. 6. 이 약관에 동의하는 것은
				정기적으로 회사 및 모바일 어플리케이션을 통하여 약관의 변경사항
				및 운영방침 등을 확인하는 것에 동의하는 것을 의미합니다. 회원은
				약관의 변경에 대하여 주의의무를 다하여야 하며, 변경된 약관의
				부지로 인한 회원의 피해는 회사가 책임지지 않습니다. 제 4조
				(개인정보보호) 1. 회사는 이용자의 개인정보 수집 시 서비스제공을
				위하여 필요한 범위에서 최소한의 개인정보를 수집합니다. 2. 회사는
				회원가입 시 서비스 운영에 필요한 정보를 미리 수집하지 않습니다.
				다만, 관련 법령상 의무이행을 위하여 본인확인이 필요한 경우로서
				최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
				3. 회사는 서비스 이용자의 개인정보를 수집·이용 시, 사전에 그
				목적과 범위 등의 법령 상의 내용을 고지하고 동의를 받습니다. 4.
				회사는 수집된 개인정보를 서비스 운영 및 연구 목적 외의 용도로
				이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게
				제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을
				고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는
				경우에는 예외로 합니다. 5. 회사가 제2항과 제3항에 의해 서비스
				이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의
				신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및
				이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적
				및 제공할 정보의 내용) 등 『개인정보 보호법』.「정보통신망
				이용촉진 및 정보보호 등에 관한 법률」이 규정한 사항을 미리
				명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수
				있습니다. 6. 서비스 이용자는 언제든지 회사가 가지고 있는 자신의
				개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에
				대해 지체 없이 필요한 조치를 취할 의무를 집니다. 서비스 이용자가
				오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지
				당해 개인정보를 이용하지 않습니다. 7. 회사는 개인정보 보호를
				위하여 서비스 이용자의 개인정보를 취급하는 자를 최소한으로
				제한하여야 하며 서비스 이용자의 개인정보의 분실, 도난, 유출,
				동의 없는 제3자 제공, 변조 등으로 인한 서비스 이용자의 손해에
				대하여 모든 책임을 집니다. 8. 회사 또는 그로부터 개인정보를
				제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한
				때에는 당해 개인정보를 지체 없이 파기합니다. 9. 회사는
				개인정보의 수집·이용·제공에 관한 동의란을 미리 선택한 것으로
				설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한
				서비스 이용자의 동의거절 시 제한되는 서비스를 구체적으로
				명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한
				동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지
				않습니다. 10. 회원이 회사 또는 서비스에 관하여 이메일 또는 그 외
				방식으로 회사에 제공하는 피드백, 제안, 아이디어 또는 기타 정보나
				자료 등은 비밀정보가 아니며 회사의 고유 재산임에 동의합니다.
				회사는 회원에게 통지나 보상을 제공함이 없이 상업성 여부를 불문한
				어떠한 목적으로든 그러한 자료 및 정보들을 무제한적으로
				이용하거나 전파할 권리를 보유합니다. 회원은 회원이 회사에게
				제공한 다양한 정보 및 자료에 관하여 가질 수 있는 권리(저작권
				또는 저작인격권 포함) 일체를 포기하거나 회사에 주장하지 않는
				것에 동의합니다. 회사는 사용자의 의견을 환영하나, 보상을
				원하거나 그에 관한 소유권 또는 청구권을 지속적으로 보유하고자
				하는 경우에는 회사와 아이디어를 공유하지 않으시기 바랍니다. 제
				5조 (회원가입) 1. 이용자는 다음 각 호의 방법으로 회원가입을
				신청합니다. 다만, 회사는 운영하는 매체 및 서비스의 종류에 따라
				별도의 회원가입 신청 방법을 정할 수 있습니다. ①회사가 정한 가입
				양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를
				하는 방법 ②기타 회사에서 정하는 방법 2. 회사는 제1항과 같이
				회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는
				한 회원으로 등록합니다. ①가입신청자가 이 약관 제7조에 의하여
				이전에 회원자격을 상실한 적이 있는 경우, 다만 회사의 회원 재가입
				승낙을 얻은 경우에는 예외로 합니다. ②등록 내용에 허위, 기재누락,
				오기가 있는 경우 ③기타 회원으로 등록하는 것이 회사의 기술상
				현저히 지장이 있다고 판단되는 경우 3. 회원가입의 성립시기는
				회사의 승낙이 회원에게 도달한 시점으로 합니다. 4. 회원은
				등록사항에 변경이 있는 경우, 상당한 기간 이내에 회사에 대하여
				회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다. 제 6조
				(회원 탈퇴 및 자격상실) 1. 회원은 회사에 언제든지 탈퇴를 요청할
				수 있으며 회사는 즉시 회원탈퇴를 처리합니다. 단, 회원탈퇴 시
				회원이 보유하고 있었던 개인적인 콘텐츠, 정보 등 회원으로서의
				모든 혜택은 소멸됩니다. 2. 탈퇴 전에 회원이 서비스를 위하여 또는
				이와 관련하여 지불한 사용료가 있는 경우, 그러한 사용료는
				관련법령에서 정한 내용에 따라 환불됩니다. 다만, 환불을 청구하지
				않고 해지를 하는 경우 관련 기록 삭제 등으로 인해 환불에 어려움이
				발생할 수 있으니 해지 전 필요한 환불 절차를 모두 이행하여
				주시기를 부탁드립니다. 또한, 계정 해지 시에도 이미 발생한 지불
				의무는 면제되지는 않습니다. 3. 서비스가 해지되거나 중단되는
				경우, 회사는 이를 통지하고 콘텐츠 회수 기회를 제공하기 위하여
				합리적인 노력을 합니다. 4. 회원탈퇴 시 회원정보는 지체 없이
				파기합니다. 다만, 다음 사유의 경우에는 아래의 보존 이유를 위해
				명시한 기간 동안 보존합니다. ①관계 법령에 근거한 정보보유 사유
				가.국세기본법, 소비자기본법 등 ②회사 내부 방침에 근거한 정보보유
				사유 가. 보존 항목 : 전화번호, 생년월일, 성별, 이메일, 사진등
				수집 회원 정보 나. 보존 이유 : 부정 가입 및 이용 방지 부정 가입
				: 타인 명의를 도용하여 가입하거나 이벤트 응모를 목적으로 다수의
				가성 회원 다. 명의로 가입하는 경우 부정 이용 : 명의도용, 가성
				회원가입으로 부정한 목적의 거래행위(이벤트 응모 등)를 하는
				경우와 컨텐츠 등 및 Q&A 등에 욕설, 업체 홍보 글을 게시하는 경우
				라.보존 기간 : 1개월 5. 회원이 다음 각 호의 사유에 해당하는
				경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다. ①가입 신청
				시에 허위 내용을 등록하거나 타인을 정보를 도용한 경우 ②다른
				사람의 회사 이용을 방해하거나 그 정보를 도용하는 등 전자상거래
				질서를 위협하는 경우 ③회사를 이용하여 법령 또는 이 약관이
				금지하거나 공서양속에 반하는 행위를 하는 경우 ④다음 각 목에
				해당하는 행위를 하여 회사의 건전한 경영과 서비스 운영을 방해하는
				경우 가. 회사에 대한 근거 없는 허위의 사실을 유포하여 회사의
				명예를 실추시키는 경우 나. 회사의 직원에 대한 모욕, 폭언, 협박,
				성희롱 등으로 회사의 업무 운영을 방해하는 경우 다. 소비자원이
				고시한 소비자분쟁해결기준을 초과하는 과도한 보상을 상습적으로
				요청하는 경우 라. 회원이 게재하는 컨텐츠 등이 다음 각 호에
				해당하는 경우 1)타인의 권리나 명예, 신용 등 기타 정당한 이익을
				침해하는 경우 2)범죄 행위와 관련되거나, 공공질서 및 사회적
				미풍양속에 위반되는 내용일 경우 3)불법물, 음란물 또는 청소년
				유해매체물의 게시, 광고, 사이트를 링크하는 경우 4)회사가
				제공하는 제품 및 서비스와 관련 없는 내용일 경우 5)정보 통신
				기기의 오작동을 일으킬 수 있는 악성 코드나 데이터를 포함하는
				경우 6)허위 정보인 경우 7)파트너사로부터 혜택을 받은 생성된
				컨텐츠이나 '광고','협찬' 등 당사가 제공한 운영정책에 따라
				표기하지 않은 경우 8)기타 본 이용약관, 당사가 제공하는 각종
				운영정책 또는 관련 법령에 위반하는 경우 6. 회원은 타 회원의 신고
				및 회사의 판단에 의해 계정 접근 및 계정 사용이 정지될 수
				있습니다. 정지 시점과 재사용 가능 여부는 회원에게 별도 고지
				됩니다. 7. 회사가 회원 자격을 제한 및 정지시킨 후, 동일한 행위가
				2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우
				회사는 회원자격을 상실시킬 수 있습니다. 회사가 회원자격을
				상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를
				통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여
				소명할 기회를 부여합니다. 제 7조 (회원에 대한 통지) 1. 회사가
				회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한
				SNS 계정, 전자우편 주소, 핸드폰번호(SMS, 카카오톡 계정)로 할 수
				있습니다. 2. 회사는 불특정다수 서비스 이용자에 대한 통지의 경우
				1주일 이상 서비스화면 또는 회사 내 게시판에 게시함으로써 개별
				통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여
				중대한 영향을 미치는 사항에 대하여는 개별 통지 합니다. 제 8조
				(서비스의 제공 및 변경) 1. 회사는 다음 각 호의 서비스 업무를
				제공합니다. ① 컬러 분석 서비스 제공 ② 광고서비스업 ③ 기타 회사가
				정하는 업무 2. 회사는 서비스 이용자와 파트너사 간에 재화 등을
				거래할 수 있도록 재화 등의 정보만을 제공하며, 회사는 파트너사가
				제공하는 재화 등의 정보 및 판매의 제반 행위에 관련해서는 일체의
				책임을 지지 않습니다. 제 9조 (서비스의 중단) 1. 회사는
				정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가
				발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
				2. 회사는 제1항 사유로 서비스의 제공이 일시적으로 중단됨으로
				인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단,
				회사가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
				아니합니다. 3. 사업종목의 전환, 사업의 포기, 업체간의 통합 등의
				이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 제8조에 정한
				방법으로 회원에게 통지합니다. 제 10조 (회사의 의무) 1. 회사는
				법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며
				이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를
				제공하는데 최선을 다하여야 합니다. 2. 회사는 서비스 이용자가
				안전하게 회사의 서비스를 이용할 수 있도록 이용자의
				개인정보보호를 위한 보안 시스템을 갖추어야 합니다. 3. 회사는
				서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이
				정당하다고 인정할 경우에는 이를 처리하여야 합니다. 4. 회사는
				회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나
				기타 회사가 적당하다고 판단되는 방법 등을 통하여 회원에게
				처리과정 및 결과를 전달합니다. 제 11조 (회원의 ID 및 비밀번호에
				대한 의무) 1. ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
				2. 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
				안됩니다. 3. 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가
				사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의
				안내가 있는 경우에는 그에 따라야 합니다. 제 12조 (서비스
				이용자의 의무) 1. 서비스 이용자는 다음 각 호의 행위를 하여서는
				안 됩니다. ①신청 또는 변경 시 허위 내용의 등록 ②타인의 정보 도용
				③외설 또는 폭력적인 메시지, 이미지, 기타 공서양속에 반하는
				정보를 회사에 공개 또는 게시하는 행위 ④회사에 게시된 정보의 변경
				⑤회사 및 기타 제3자의 지식재산권 등에 대한 침해 ⑥타 법령 및 본
				이용약관에 위반되는 불법적 행위 2. 서비스 이용자가 본 조의 의무
				사항을 위반하여 발생한 결과에 대해 회사는 책임을 지지않습니다.
				또한 회사에 손해가 발생한 경우 서비스 이용자는 회사에 대한
				손해배상 의무를 지며 회사는 해당 회원에 대해 서비스 이용정지 및
				적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다. 제 13조
				(회원의 책임) 1. 회원은 본인의 책임 하에 본 서비스 및 본
				서비스에서 제공되는 각종 컨텐츠 등을 이용하고 생성하며, 회원은
				본 서비스를 통해 이루어진 일체의 행위 및 그 결과에 대하여 책임을
				집니다. 2. 회원은 회원이 제작한 콘텐츠를 타인이 열람할 수 있으며
				개인정보보호 설정의 조정을 통해 콘텐츠에 대한 접근을 제한할 수
				있음을 이해합니다. 3. 회원은 자신의 사용자 콘텐츠 및 그로부터
				발생하는 청구에 대하여 전적인 책임을 부담하며 회사는 어떠한
				사용자 콘텐츠 또는 그로부터 발생하는 청구에 대해서도 책임 또는
				법적 책임을 부담하지 않는 데에 동의합니다. 4. 회사는 어느 때라도
				이유를 불문하고 사용자 콘텐츠를 검토, 확인 및 삭제할 권리와
				완전한 재량을 보유하는 한편, 그에 대한 의무를 지지는 않습니다.
				5. 회원은 회원의 사용자 콘텐츠에 대한 소유권 일체를 보유합니다.
				그러나 사용자 콘텐츠를 회사에 제출함으로써 회원은 알려지지
				않았거나 추후 개발되는 것을 포함한 모든 매체 또는 배포 방법을
				이용하여 그러한 사용자 콘텐츠를 이용, 재현, 변경, 각색, 편집,
				출판, 2차 저작물 제작, 배포, 공연, 홍보, 전시 및 진열할 수 있는,
				취소 불가능하고 비독점적인, 전세계적, 영구적, 무상의, 2차
				라이선스 부여 및 양도 가능한 라이선스를 회사에 부여합니다. 6.
				회원은 서비스에 접근하거나 서비스를 이용함으로써 모욕적이거나
				외설적이거나 기타 불쾌한 것으로 간주되는 타인의 자료에 노출될 수
				있음을 인정하고 이에 동의하며, 그러한 위험을 감수하는 데에
				동의합니다. 회사의 웹사이트 또는 서비스를 통해 표현되는 견해는
				반드시 회사의 견해를 대표하지는 않습니다. 회사는 회원 또는
				서비스이용자가 게시한 특정 콘텐츠를 찬성하거나 지지하지
				않습니다. 7. 회사는 콘텐츠 보호를 위해 가능한 보안 조치를 제공할
				것이나 콘텐츠의 공개로 인해 초래되는 손해에 대한 책임을 지지
				않습니다. 8. 회원은 다음 각호의 행위를 한 경우, 자신의 책임과
				비용으로 관련 분쟁을 해결하며, 이에 관하여 회사는 책임을 지지
				않습니다. ①허위·거짓정보·공공질서 및 미풍양속에 위반되는 컨텐츠
				등을 업로드·재생산 등을 하는 경우 ②업로드 된 컨텐츠 등이 타인의
				명예를 훼손하거나, 사생활을 침해할 경우 ③회사 또는 전자통신망
				등에서 제 3자의 사진 및 영상물 등을 공유 또는 유포하였을 경우
				④회원의 행위가 타인의 지식재산권 및 관계 법령, 본 약관에
				위반되는 행위인 경우 ⑤그 밖의 사유로 타인의 권리를 침해한 경우
				제 14조 (회원 등급) 회사는 서비스를 이용하는 회원의 활동에 따라
				회원 등급을 설정할 수 있으며, 등급에 따라 서비스 이용 및
				혜택지급에 차등을 둘 수 있다. 제 15조 (앱내 화폐 제도) 1. 앱내
				화폐는 회사가 운영하는 서비스 이용 과정에서 발생하며 개별 활동에
				따라 유효기간, 금액 등이 다르게 적용됩니다. 2. 앱내 화폐은
				고객의 환가성 자산이 아니므로 현금으로 환원되지 않으며, 부정
				행위를 통한 적립 발견 시 환원 및 소멸 조치될 수 있습니다. 3.
				앱내 화폐는 명시 된 기간 경과 시 자동 소멸됩니다. 구매된 앱내
				화폐는 다시 현금으로 재환원되지 않습니다. 4. 앱내 화폐는 회원의
				탈퇴 및 자격상실 시 소멸되며 복구되지 않습니다. 5. 앱내 화폐에
				대한 적립, 사용, 유효기간 등 관련 정책은 회사 사정에 의해 변경될
				수 있습니다. 제 16조 (경품지급) 회사가 회원에게 경품을 지급할
				경우 이용자는 경품의 추첨일로부터 3개월 이내에 회사에 경품
				지급을 요청할 수 있습니다. 단, 경품 추첨 및 경품지급과 관련하여
				회사가 별도의 통지 또는 공지를 하는 경우에는 그에 따릅니다. 제
				17조 (협력사와의 관계) 회원은 협력사의 재화 등을 광고하는 활동에
				참여할 수 있으며, 이는 회원과 협력사 간의 직접 계약에 의한
				것으로 회사는 이에 관여하거나 분쟁 해결에 대한 책임을 지지
				않습니다. 제 18조 (서비스 양도 제한) 회원은 서비스의 이용 권한,
				기타 서비스 이용 계약상의 지위를 타인에게 양도, 증여할 수 없으며
				이를 담보로 제공할 수 없습니다. 다만, 회사의 서비스는 사업상
				기타 운영상의 이유 등으로 양도될 수 있습니다. 제 19조 (컨텐츠
				생성 가이드 및 관리/제한 행위) 1. 회원은 수시로 갱신될 수 있는
				회사의 커뮤니티 가이드라인을 준수하는 데에 동의합니다. 2.
				컨텐츠는 다음의 가이드에 따라 생성될 것을 지향 합니다. ① 컬러
				분석을 원하는 다양한 이미지 등록 ②퍼스널컬러 정보 등록 ③ 회원
				특징 정보 등록 ④ 기타 정보 등록 3. 회원의 컨텐츠가 정보통신망법
				및 지식재산권 등 기타 법에 위반되는 내용을 포함하는 경우,
				컨텐츠의 권리자 등은 관련법이 정한 절차에 따라 해당 컨텐츠의
				게시중단 및 삭제 등을 회사에게 요청할 수 있으며 회사는 해당
				사실을 회원에게 통지합니다. 4. 회사는 전항에 따라 이의가 제기된
				컨텐츠가 본 약관 또는 관계 법령에 위반된다고 판단되는 경우
				회원에게 통지함과 동시에 컨텐츠의 게시 보류, 임시 삭제 등의
				조치를 취할 수 있습니다. 5. 회사는 본 조에 따라 컨테츠 등에 대한
				이의제기, 분쟁 발생으로 회원의 이용정지, 컨텐츠 등의 삭제 등이
				발생한 경우 회원에게 통보 합니다. 6. 회사는 서비스 종료, 운영
				상의 이유가 발생한 경우, 서비스 종료 사실을 통보한 뒤 별도의
				보관 없이 컨텐츠 등을 삭제할 수 있습니다. 7. 본 약관에
				명시적으로 규정된 경우를 제외하고, 회사는 콘텐츠 사용에 대한
				명시적이거나 묵시적인 권리를 부여하지 않습니다. 8. 회원은 본
				약관에서 명시적으로 기술된 경우를 제외하고 콘텐츠, 서비스 또는
				관련 소프트웨어의 전부 또는 일부를 복제, 재현, 전재, 프레임
				삽입, 다운로드, 전송, 변경, 전시, 역설계, 매도, 매도 참여, 대여,
				임대, 대출, 양도, 배포, 허가, 재허가하거나 어떠한 방식으로든
				활용하지 않는 데에 동의합니다. 제 20조 (지식재산권의 귀속 및
				이용제한) 1. 회사가 작성한 저작물에 대한 저작권 기타
				지식재산권은 회사에 귀속합니다. 2. 서비스 이용자는 회사 및
				서비스를 통하여 얻은 정보 중 회사에게 지식재산권이 귀속된 정보를
				회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에
				의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는
				안됩니다. 3. 회사는 회원이 작성한 컨텐츠 등과 재화 등에 관련된
				모든 컨텐츠 등은 회사가 무상으로 직접 이용하거나 회사와 별도
				계약이 체결된 제휴사로 하여금 이용하게 할 수 있고, 회원은 이를
				허락합니다. 제 21조 (분쟁해결) 1. 회사는 서비스 이용자가
				제기하는 정당한 의견이나 불만을 반영하고 피해가 발생한 경우, 그
				피해를 보상처리하기 위하여 피해보상처리기구를 설치·운영합니다.
				2. 회사는 서비스 이용자로부터 제출되는 불만사항 및 의견을
				우선적으로 처리합니다. 다만, 신속한 처리가 곤란한 경우에는
				서비스 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다. 제
				22조 (재판권 및 준거법) 1. 회사와 이용자 간에 발생한 전자상거래
				분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가
				없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다.
				다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국
				거주자의 경우에는 민사소송법상의 관할법원에 제기합니다. 2.
				회사와 이용자간에 제기된 전자상거래 소송에는 한국법을
				적용합니다. 부칙. 이 약관은 2024년 7월 25일부터 적용됩니다.
				담당자 연락처: ask@ocollor.com
			</div>
			<button
				className="fixed bottom-8 left-1/2 transform -translate-x-1/2 w-[320px] h-[52px] px-10 py-4 rounded-tr-full bg-[#4430BD] text-white font-bold text-[15px] leading-6 text-center"
				style={{
					padding: '16px 40px',
					borderRadius: '100px',
				}}
				onClick={goBackwardSignup}
			>
				확인
			</button>
		</div>
	);
};

export default TermsOfServiceAgreement;
