export const convertImageFileToBase64 = (file: any): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function (event: any) {
			resolve(event.target.result);
		};
		reader.onerror = function (error) {
			reject(error);
		};
		reader.readAsDataURL(file);
	});
};

// Blob을 Base64 문자열로 변환하는 함수
export const blobToBase64 = (blob: Blob): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			resolve(reader.result as string);
		};
		reader.onerror = reject;
	});
};

export const dataURLToBlob = (dataURL: string | null) => {
	if (!dataURL) {
		return null;
	}
	// DataURL의 Base64 문자열 부분과 MIME 타입 부분을 분리합니다.
	const parts = dataURL.split(';base64,');
	const contentType = parts[0].split(':')[1];
	const raw = window.atob(parts[1]);
	const rawLength = raw.length;
	const uInt8Array = new Uint8Array(rawLength);

	for (let i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i);
	}

	return new Blob([uInt8Array], { type: contentType });
};

// Base64 문자열을 Blob으로 변환하는 함수 두 차이 알아보기
// export const base64ToBlob = (base64: string): Blob => {
// 	// node.js에서 사용할때는 문제가 될 수 있는데 웹 프론트에서는 문제없음
// 	const byteString = atob(base64.split(',')[1]);
// 	const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
// 	const ab = new ArrayBuffer(byteString.length);
// 	const ia = new Uint8Array(ab);
// 	for (let i = 0; i < byteString.length; i++) {
// 		ia[i] = byteString.charCodeAt(i);
// 	}
// 	return new Blob([ab], { type: mimeString });
// };
