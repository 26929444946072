import { SvgIconProps } from "./icon";

const SvgIcSpring = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill={props.fill ?? 'none'}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color}
      strokeWidth={1.5}
      d="M12 20.5v-7M5.478 11.232c2.365 2.435 5.113 3.553 6.138 2.497 1.025-1.055-.06-3.885-2.425-6.32C7.785 5.96 5.88 5.352 4.567 5.096c-.82-.16-1.49.53-1.334 1.375.248 1.351.839 3.313 2.245 4.761Z"
    />
    <path
      stroke={props.color}
      strokeWidth={1.5}
      d="M18.522 11.232c-2.365 2.435-5.113 3.553-6.138 2.497-1.025-1.055.06-3.885 2.425-6.32 1.406-1.449 3.311-2.057 4.624-2.313.82-.16 1.49.53 1.334 1.375-.248 1.351-.839 3.313-2.245 4.761Z"
    />
  </svg>
);
export default SvgIcSpring;
